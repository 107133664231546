import { TableColumn } from '@/classes'

const Columns = [
  new TableColumn('Order No.', 'purchaseOrderNoRef', true, 'desc', 'left'),
  new TableColumn('Order Date', 'purchaseOrderDate', true, 'desc', 'left'),
  new TableColumn('Quote No', 'quoteNoRef', true, 'desc', 'left'),
  new TableColumn('PartsCheck Order No', 'partsCheckPurchaseOrderNo', true, 'desc', 'centered'),
  new TableColumn('Rego', 'rego', true, 'desc', 'left'),
  new TableColumn('Make/Model', 'makeModel', true, 'asc', 'left'),
  new TableColumn('Vendor', 'vendorName', true, 'asc', 'left'),
  new TableColumn('Customer', 'customerName', true, 'asc', 'left'),
  new TableColumn('Ord.', 'orderQty', false, 'asc', 'right'),
  new TableColumn('Rec.', 'receivedQty', false, 'asc', 'right'),
  new TableColumn('Ret.', 'returnQty', false, 'asc', 'right'),
  new TableColumn('Total', 'totalAmount', false, 'asc', 'right'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

const DetailSummaryColumns = [
  new TableColumn('Type', 'itemType', true, 'asc', 'left'),
  new TableColumn('Description', 'description', true, 'asc', 'left'),
  new TableColumn('Ordered', 'order', false, 'desc', 'right'),
  new TableColumn('Received', 'received', false, 'desc', 'right'),
  new TableColumn('Returned', 'returnQty', false, 'desc', 'right'),
  new TableColumn('Total', 'total', false, 'desc', 'right')
]

const OtherPurchasesColumns = [
  new TableColumn('Description', 'description', true, 'asc', 'left'),
  new TableColumn('Part Number', 'partNo', true, 'asc', 'left'),
  new TableColumn('Order Qty', 'orderQty', false, 'desc', 'right'),
  new TableColumn('Unit Price', 'unitPrice', false, 'desc', 'right'),
  new TableColumn('Total', 'total', false, 'desc', 'right'),
  new TableColumn('Received', 'received', false, 'desc', 'right'),
  new TableColumn('', 'action', false, 'desc', 'right')
]

const QuotedPartsColumns = [
  new TableColumn('Item No', 'itemNo', true, 'asc', 'left'),
  new TableColumn('Description', 'itemDesc', true, 'asc', 'left'),
  new TableColumn('Side', 'side', true, 'asc', 'centered'),
  new TableColumn('New?', 'mark', true, 'asc', 'left'),
  new TableColumn('Qty', 'itemQuantity', false, 'desc', 'right'),
  new TableColumn('Ordered', 'otherPoOrderedQty', false, 'desc', 'right'),
  new TableColumn('Purchase Order', 'otherOrderedPo', false, 'desc', 'left'),
  new TableColumn('In Stock', 'quantityInStock', false, 'desc', 'right'),
  new TableColumn('Quoted U.Price', 'value', false, 'desc', 'right'),
  new TableColumn('Part No.', 'partNo', true, 'asc', 'left'),
  new TableColumn('U.Buy', 'buyPrice', false, 'desc', 'right'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

const OrderPartsColumns = [
  new TableColumn('Item No', 'itemNo', true, 'asc', 'left'),
  new TableColumn('Description', 'description', true, 'asc', 'left'),
  new TableColumn('Side', 'side', true, 'asc', 'centered'),
  new TableColumn('New?', 'mark', true, 'asc', 'left'),
  new TableColumn('Qty', 'Quantity', false, 'desc', 'right'),
  new TableColumn('Order U.Price', 'dealerCost', false, 'desc', 'right'),
  new TableColumn('Total', 'total', false, 'desc', 'right'),
  new TableColumn('Part No.', 'partNo', true, 'desc', 'left'),
  new TableColumn('Received', 'received', false, 'desc', 'right'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

const QuotedItemsColumns = [
  new TableColumn('Item No', 'itemNo', true, 'asc', 'left'),
  new TableColumn('Description', 'itemDesc', true, 'asc', 'left'),
  new TableColumn('Ordered', 'otherPoOrderedQty', false, 'desc', 'right'),
  new TableColumn('Purchase Order', 'otherOrderedPo', false, 'desc', 'left'),
  new TableColumn('In Stock', 'quantityInStock', false, 'desc', 'right'),
  new TableColumn('Quoted Price', 'quotedPrice', false, 'desc', 'right'),
  new TableColumn('', 'action', false, '', 'centered')
]

const ItemsToOrderColumns = [
  new TableColumn('Item No', 'itemNo', true, 'asc', 'left'),
  new TableColumn('Description', 'description', true, 'asc', 'left'),
  new TableColumn('Order Price', 'orderPrice', false, 'desc', 'right'),
  new TableColumn('Received', 'received', false, 'desc', 'right'),
  new TableColumn('', 'action', false, '', 'centered')
]

const OrderReceiptDetailColumns = [
  new TableColumn('Item No', 'itemNo', true, 'asc', 'left'),
  new TableColumn('Description', 'description', true, 'asc', 'left'),
  new TableColumn('Side', 'side', true, 'asc', 'left'),
  new TableColumn('Owing', 'owing', false, 'desc', 'centered'),
  new TableColumn('Part No', 'partNo', true, 'asc', 'left'),
  new TableColumn('Qte Price', 'quotePrice', false, 'desc', 'right'),
  new TableColumn('Rec\'d', 'received', false, 'desc', 'right'),
  new TableColumn('Before GST', 'dealerCost', false, 'desc', 'right'),
  new TableColumn('After GST', 'totalAmount', false, 'desc', 'right'),
  new TableColumn('GST', 'gstAmount', false, 'desc', 'right'),
  new TableColumn('G/L Account', 'glAccount', false, 'desc', 'left')
]

const OrderReceiptListColumns = [
  new TableColumn('Invoice No', 'invoiceNo', true, 'asc', 'left'),
  new TableColumn('Receipted Date', 'invoiceDate', true, 'desc', 'left'),
  new TableColumn('Vendor ABN', 'abn', false, 'asc', 'left'),
  new TableColumn('', 'action', false, 'desc', 'left')
]

const RemarkColumns = [
  new TableColumn('Created', 'remarkDateTime', false, 'desc', 'left'),
  new TableColumn('Remark', 'remarks', false, 'desc', 'left'),
  new TableColumn('', 'action', false, 'asc', 'centered')
]

const AttachmentsColumns = [
  new TableColumn('Date', 'date', false, 'desc', 'left'),
  new TableColumn('File name', 'fileName', false, 'desc', 'left'),
  new TableColumn('File size', 'fileSize', false, 'desc', 'left'),
  new TableColumn('', 'action', false, 'desc', 'left')
]

export {
  Columns,
  DetailSummaryColumns,
  OtherPurchasesColumns,
  QuotedPartsColumns,
  OrderPartsColumns,
  QuotedItemsColumns,
  ItemsToOrderColumns,
  OrderReceiptDetailColumns,
  OrderReceiptListColumns,
  RemarkColumns,
  AttachmentsColumns
}