import { render, staticRenderFns } from "./OverviewSublet.vue?vue&type=template&id=5f3a823e&scoped=true"
import script from "./OverviewSublet.vue?vue&type=script&lang=js"
export * from "./OverviewSublet.vue?vue&type=script&lang=js"
import style0 from "./OverviewSublet.vue?vue&type=style&index=0&id=5f3a823e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f3a823e",
  null
  
)

export default component.exports