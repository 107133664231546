<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile is-parent">
      <article class="tile is-child box detail-page-tile">
        <div class="columns is-gapless">
          <div class="column">
            <p class="title">Remarks</p>
          </div>
          <div v-if="!readOnlyView"
            class="column">
            <div class="field">
              <div class="control is-pulled-right">
                <div class="is-pulled-right">
                  <a class="button is-small is-success tooltip"
                    data-tooltip="Add Remarks"
                    @click="toggleRemarkModal()">
                    <span class="icon">
                      <i class="mdi mdi-18px mdi-plus" />
                    </span>
                    <span>Remarks</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
          :columns="columns"
          :page-index="filter.pageIndex"
          :page-size="filter.pageSize"
          :total-rows="remarkTotalRows"
          :is-loading="isTableLoading"
          :sort-column="filter.sortColumn"
          :sort-order="filter.sortOrder"
          :show-header="false"
          @pageChanged="onPageChange"
          @sort="sort">
          <tr v-for="(remark, index) in pagedRemarks"
            :key="remark.remarkId"
            @click="highlightSelected(index, $event)">
            <td class="is-content-width">
              <span class="tag is-info">{{ $filters.formatDateTimeLocale(remark.remarkDateTime, $userInfo.locale) }}</span>
            </td>
            <td class="is-remark">
              <div v-show="editRemarkRowNumber === -1 || editRemarkRowNumber !== index">{{ remark.remarks }}</div>
              <textarea v-if="editRemarkRowNumber === index"
                @blur="editRemarkRowNumber = -1"
                class="textarea"
                v-model="remark.remarks"
                rows="3"
                v-focus-inserted />
            </td>
            <td class="has-text-centered is-content-width has-vertical-middle">
              <a
                class="button is-primary is-small is-inverted"
                @click="editRemark(index)"
                :disabled="readOnlyView">
                <span class="icon is-medium">
                  <i
                    class="mdi mdi-24px"
                    :class="[editRemarkRowNumber !== index ? 'mdi-lead-pencil' : 'mdi-check']" />
                </span>
              </a>
              <a
                class="button is-danger is-small is-inverted"
                @click="deleteRemark(index, remark.remarkId, remark.remarkTypeId)"
                :disabled="readOnlyView">
                <span class="icon is-medium">
                  <i class="mdi mdi-delete mdi-24px" />
                </span>
              </a>
            </td>
          </tr>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <span icon="icon is-large">
                  <i class="mdi mdi-48px mdi-emoticon-sad" />
                </span>
                <p>Nothing</p>
              </div>
            </section>
          </template>
        </bulma-table>
      </article>
    </div>
    <div class="tile is-parent">
      <article class="tile is-child box detail-page-tile">
        <div class="columns is-gapless">
          <div class="column">
            <p class="title">Printed Remarks</p>
          </div>
          <div v-if="!readOnlyView"
            class="column">
            <div class="field">
              <div class="control is-pulled-right">
                <div class="is-pulled-right">
                  <a
                    class="button is-small is-success tooltip"
                    data-tooltip="Add Remarks"
                    @click="toggleRemarkModal()">
                    <span class="icon">
                      <i class="mdi mdi-18px mdi-plus" />
                    </span>
                    <span>Remarks</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="field">
          <bulma-table
            class="table is-bordered is-striped is-narrow is-fullwidth"
            :columns="columns"
            :page-index="printedFilter.pageIndex"
            :page-size="printedFilter.pageSize"
            :total-rows="printedRemarkTotalRows"
            :is-loading="isTableLoading"
            :sort-column="printedFilter.sortColumn"
            :sort-order="printedFilter.sortOrder"
            :show-header="false"
            @pageChanged="onPrintedRemarksPageChange"
            @sort="sort">
            <tr
              v-for="(remark, index) in pagedPrintedRemarks"
              :key="remark.remarkId">
              <td class="is-content-width">
                <span class="tag is-info">{{ $filters.formatDateTimeLocale(remark.remarkDateTime, $userInfo.locale) }}</span>
              </td>
              <td class="is-remark">
                <div v-show="editPrintedRemarkRowNumber === -1 || editPrintedRemarkRowNumber !== index">{{ remark.remarks }}</div>
                <textarea
                  v-if="editPrintedRemarkRowNumber === index"
                  @blur="editPrintedRemarkRowNumber = -1"
                  class="textarea"
                  v-model="remark.remarks"
                  rows="3"
                  v-focus-inserted />
              </td>
              <td class="has-text-centered is-content-width has-vertical-middle">
                <a
                  class="button is-primary is-small is-inverted"
                  @click="editPrintedRemark(index)"
                  :disabled="readOnlyView">
                  <span class="icon is-medium">
                    <i
                      class="mdi mdi-24px"
                      :class="[editPrintedRemarkRowNumber !== index ? 'mdi-lead-pencil' : 'mdi-check']" />
                  </span>
                </a>
                <a
                  class="button is-danger is-small is-inverted"
                  @click="deleteRemark(index, remark.remarkId, remark.remarkTypeId)"
                  :disabled="readOnlyView">
                  <span class="icon is-medium">
                    <i class="mdi mdi-delete mdi-24px" />
                  </span>
                </a>
              </td>
            </tr>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <span icon="icon is-large">
                    <i class="mdi mdi-48px mdi-emoticon-sad" />
                  </span>
                  <p>Nothing</p>
                </div>
              </section>
            </template>
          </bulma-table>
        </div>
      </article>
    </div>
    <remark-modal
      v-if="value && isRemarkModalActive"
      :show-remark="true"
      :show-printed-remark="true"
      :show-recurring-remark="true"
      :active.sync="isRemarkModalActive"
      @add="addRemarks"
      @cancel="toggleRemarkModal()">
      <p slot="text-title">Add Purchase Order Remarks</p>
    </remark-modal>
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'
import { RemarkModal } from '@/components/BulmaModal'
import { EventHubTypes, RemarkTypes } from '@/enums'
import { RemarkColumns } from './columns'
import BulmaTable from '@/components/BulmaTable'
import { FocusInserted } from '@/components/directives'
import { RemarkModel } from '@/classes/viewmodels'

export default {
  name: 'PurchaseOrderRemarks',
  directives: {
    FocusInserted
  },
  components: {
    RemarkModal,
    BulmaTable
  },
  mixins: [DateTimeFiltersMixin, NumberFiltersMixin],
  props: {
    value: null,
    readOnlyView: {
      type: Boolean
    }
  },
  data() {
    return {
      innerValue: null,
      isRemarkModalActive: false,
      selectedRow: null,
      isTableLoading: false,
      filter: {
        sortColumn: RemarkColumns[0].name,
        sortOrder: RemarkColumns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 3
      },
      printedFilter: {
        sortColumn: RemarkColumns[0].name,
        sortOrder: RemarkColumns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 3
      },
      editRemarkRowNumber: -1,
      editPrintedRemarkRowNumber: -1,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ]
    }
  },
  computed: {
    columns() {
      return RemarkColumns
    },
    remarkTypes() {
      return RemarkTypes
    },
    remarkTotalRows() {
      return this.innerValue.remarks.filter(r => r.remarkTypeId === RemarkTypes.Remark && !r.isDeleted).length
    },
    printedRemarkTotalRows() {
      return this.innerValue.printedRemarks.filter(r => r.remarkTypeId === RemarkTypes.PrintedRemark && !r.isDeleted).length
    },
    pagedRemarks() {
      return this.activeRemarks.slice((this.filter.pageIndex - 1) * this.filter.pageSize, this.filter.pageIndex * this.filter.pageSize)
    },
    pagedPrintedRemarks() {
      return this.activePrintedRemarks.slice(
        (this.printedFilter.pageIndex - 1) * this.printedFilter.pageSize,
        this.printedFilter.pageIndex * this.printedFilter.pageSize
      )
    },
    activeRemarks() {
      return this.innerValue.remarks.filter(r => r.remarkTypeId === RemarkTypes.Remark && !r.isDeleted)
    },
    activePrintedRemarks() {
      return this.innerValue.printedRemarks.filter(r => r.remarkTypeId === RemarkTypes.PrintedRemark && !r.isDeleted)
    }
  },
  watch: {
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  beforeDestroy() {
    this.$eventHub.$off(EventHubTypes.EntitySaved)
    this.$eventHub.$off(EventHubTypes.EntityReload)
  },
  created() {
    this.innerValue = this.value
  },
  mounted() {
    this.$eventHub.$on(EventHubTypes.EntitySaved, () => {
      this.innerValue = _cloneDeep(this.value)
    })
    this.$eventHub.$on(EventHubTypes.EntityReload, () => {
      this.innerValue = _cloneDeep(this.value)
    })
  },
  methods: {
    addRemarks(remark, printedRemark) {
      if (remark) {
        const newRemark = new RemarkModel(this.innerValue.purchaseOrderId, RemarkTypes.Remark, remark)
        this.innerValue.remarks.splice(this.innerValue.remarks.length, 1, newRemark)
        this.innerValue.remarks.sort(function(a, b) {
          return new Date(b.remarkDateTime) - new Date(a.remarkDateTime)
        })
      }
      if (printedRemark) {
        const newPrintedRemark = new RemarkModel(this.innerValue.purchaseOrderId, RemarkTypes.PrintedRemark, printedRemark)
        this.innerValue.printedRemarks.splice(this.innerValue.printedRemarks.length, 1, newPrintedRemark)
        this.innerValue.printedRemarks.sort(function(a, b) {
          return new Date(b.remarkDateTime) - new Date(a.remarkDateTime)
        })
      }
      this.toggleRemarkModal()
    },
    toggleRemarkModal() {
      this.isRemarkModalActive = !this.isRemarkModalActive
    },
    deleteRemark(index, remarkId, remarkType) {
      let deletedRemark
      console.log('remarkType: ', remarkType)
      if (remarkType === RemarkTypes.Remark) {
        deletedRemark = this.activeRemarks.find(r => r.remarkId == remarkId)
        if (deletedRemark.isNew) {
          const index = this.innerValue.remarks.findIndex(r => r.remarkId === deletedRemark.remarkId)
          this.innerValue.remarks.splice(index, 1)
        } else {
          deletedRemark.isDeleted = true
        }
      } else if (remarkType === RemarkTypes.PrintedRemark) {
        deletedRemark = this.activePrintedRemarks.find(r => r.remarkId == remarkId)
        if (deletedRemark.isNew) {
          const index = this.innerValue.printedRemarks.findIndex(r => r.remarkId === deletedRemark.remarkId)
          this.innerValue.printedRemarks.splice(index, 1)
        } else {
          deletedRemark.isDeleted = true
        }
      } else {
        deletedRemark = this.innerValue.otherRemarks.find(r => r.remarkId == remarkId)
        deletedRemark.isDeleted = true
      }
    },
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
    },
    onPrintedRemarksPageChange(pageIndex) {
      this.printedFilter.pageIndex = pageIndex
    },
    sort(name, order) {},
    editRemark(row) {
      this.editRemarkRowNumber !== row ? (this.editRemarkRowNumber = row) : (this.editRemarkRowNumber = -1)
      this.editPrintedRemarkRowNumber = -1
    },
    editPrintedRemark(row) {
      this.editPrintedRemarkRowNumber !== row ? (this.editPrintedRemarkRowNumber = row) : (this.editPrintedRemarkRowNumber = -1)
      this.editRemarkRowNumber = -1
    }
  }
}
</script>
