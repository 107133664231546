<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile is-parent is-vertical">
      <article class="tile is-child box detail-page-tile">
        <div class="columns is-gapless">
          <div class="column">
            <p class="title">Other Purchases</p>
            <subtitle-totals :labels="['Total']"
              :totals="[otherPurchasesTotal]"
              :shows="[true]" />
          </div>
          <div v-if="!readOnlyView"
            class="column">
            <div class="control is-pulled-right">
              <div class="field-body">
                <div class="field"
                  style="margin-right: 0.5em">
                  <div v-if="!isLockPurchaseOrder"
                    class="buttons">
                    <button
                      class="button is-primary tooltip is-tooltip-topright"
                      data-tooltip="Add new item"
                      :disabled="isLockPurchaseOrder"
                      @click="addNewOtherPurchases()">
                      <span class="icon">
                        <i class="mdi"
                          :class="{ 'mdi-plus' : true}" />
                      </span>
                      <span>{{ 'Add' }}</span>
                    </button>
                  </div>
                  <div v-else>
                    <order-lock-notification />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
          :columns="columns"
          :page-index="1"
          :page-size="100"
          :total-rows="activeItems.length"
          :show-pagination="false"
          :is-loading="isTableLoading"
          :sort-column="sortColumn"
          :sort-order="sortOrder"
          @sort="sort">
          <tr v-for="(item, index) in activeItems"
            :key="item.purchaseOrderItemId"
            :class="{ 'is-selected' : selectedRow === index }">
            <td class="has-vertical-middle itemdesc-input">
              <input v-if="!readOnlyView"
                class="input"
                :class="[isDescEmpty(item.description) ? 'is-danger' : '']"
                v-focus-inserted
                placeholder="Item Description"
                :disabled="isLockPurchaseOrder"
                v-model="item.description">
              <span v-else>{{ item.description }}</span>
            </td>
            <td class="has-vertical-middle partno-input">
              <input v-if="!readOnlyView"
                class="input"
                :disabled="isLockPurchaseOrder"
                placeholder="Part Number"
                v-model="item.partNo">
              <span v-else>{{ item.partNo }}</span>
            </td>
            <td class="has-vertical-middle has-text-right qty-input">
              <vue-numeric v-if="!readOnlyView"
                class="input has-text-right"
                v-model.number="item.quantity"
                :min="1"
                :disabled="isLockPurchaseOrder"
                :precision="0"
                @input="updateValue(index, item)" />
              <span v-else>{{ item.quantity }}</span>
            </td>
            <td class="has-vertical-middle has-text-right money-input">
              <vue-numeric v-if="!readOnlyView"
                class="input has-text-right"
                v-model.number="item.unitPrice"
                :min="0"
                :disabled="isLockPurchaseOrder"
                :precision="2"
                @input="updateValue(index, item)" />
              <span v-else>{{ item.unitPrice }}</span>
            </td>
            <td class="has-vertical-middle has-text-right total-input">{{ item.quantity * item.unitPrice | formatNumber($userInfo.locale) }}</td>
            <td class="has-vertical-middle has-text-right qty-input">{{ item.receivedQty - item.returnQty }}</td>
            <td class="has-vertical-middle has-text-centered">
              <button class="button is-danger is-small is-inverted"
                @click="deleteItem(item, index)"
                :disabled="readOnlyView || isLockPurchaseOrder">
                <span class="icon is-medium">
                  <i class="mdi mdi-delete mdi-24px" />
                </span>
              </button>
            </td>
          </tr>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <span icon="icon is-large">
                  <i class="mdi mdi-48px mdi-emoticon-sad" />
                </span>
                <p>Nothing</p>
              </div>
            </section>
          </template>
          <template slot="footer">
            <tr>
              <th class="has-text-right has-text-success"
                :colspan="totalColSpan">Totals</th>
              <th class="has-text-right has-text-success"
                :class="[ otherPurchasesTotal >= 0 ? 'has-text-success' : 'has-text-danger' ]">{{ otherPurchasesTotal | formatCurrency($userInfo.locale) }}</th>
            </tr>
          </template>
        </bulma-table>
      </article>
    </div>
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import _orderBy from 'lodash/orderBy'
import BulmaTable from '@/components/BulmaTable'
import VueNumeric from '@/components/VueNumeric'
import SubtitleTotals from '@/components/SubtitleTotals'
import { EventHubTypes } from '@/enums'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { FocusInserted } from '@/components/directives'
import { OtherPurchasesColumns } from './columns'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import { PurchaseOrderItemModel } from '@/classes/viewmodels'
import OrderLockNotification from './components/PurchaseOrderLockNotification'

const title = 'Other Purchases'

export default {
  name: 'PurchaseOrderOtherPurchases',
  inject: ['$vv'],
  components: {
    BulmaTable,
    VueNumeric,
    SubtitleTotals,
    OrderLockNotification
  },
  directives: {
    FocusInserted
  },
  mixins: [NumberFiltersMixin],
  props: {
    value: null,
    readOnlyView: {
      type: Boolean
    },
    isReceive: {
      type: Boolean,
      default: false
    },
    isLockPurchaseOrder: {
      type: Boolean
    }
  },
  data() {
    return {
      innerValue: _cloneDeep(this.value),
      isTableLoading: false,
      selectedRow: false,
      sortColumn: '',
      sortOrder: ''
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    columns() {
      return OtherPurchasesColumns
    },
    activeItems() {
      return this.innerValue.otherPurchases.filter(i => !i.deleted)
    },
    otherPurchasesTotal() {
      return roundAwayFromZero(
        this.activeItems.reduce(function(total, item) {
          return total + item.dealerCost
        }, 0),
        2
      )
    },
    totalColSpan() {
      return this.columns.length - (this.$userInfo.isSupportUser ? 3 : 4)
    }
  },
  watch: {
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  beforeDestroy() {
    this.$eventHub.$off(EventHubTypes.EntitySaved)
    this.$eventHub.$off(EventHubTypes.EntityReload)
  },
  created() {},
  mounted() {
    this.$v.entity.otherPurchases.$touch()
    this.$eventHub.$on(EventHubTypes.EntitySaved, () => {
      this.innerValue = _cloneDeep(this.value)
    })
    this.$eventHub.$on(EventHubTypes.EntityReload, () => {
      this.innerValue = _cloneDeep(this.value)
    })
  },
  methods: {
    addNewOtherPurchases() {
      const newItem = new PurchaseOrderItemModel(this.$userInfo.companyId, this.innerValue.purchaseOrderId, null, 'MISC', '', '')
      this.innerValue.otherPurchases.push(newItem)
    },
    updateValue(index, item) {
      let netRecQty = item.receivedQty - item.returnQty
      if (item.quantity < netRecQty) {
        item.quantity = netRecQty
        this.$notification.openNotificationWithType('danger', title, 'Order Quantity cannot be less than Net Quantity Received: ' + netRecQty)
        return false
      }
      item.dealerCost = roundAwayFromZero(item.quantity * item.unitPrice)
    },
    deleteItem(item, index) {
      if (item.isNew) {
        const itemIndex = this.innerValue.otherPurchases
          .map(function(obj) {
            return obj.purchaseOrderItemId
          })
          .indexOf(item.purchaseOrderItemId)
        if (itemIndex >= 0) {
          this.innerValue.otherPurchases.splice(itemIndex, 1)
        }
      } else {
        let netRecQty = item.receivedQty - item.returnQty
        if (netRecQty > 0) {
          this.$notification.openNotificationWithType('danger', title, 'This item has been received. You can\'t delete it.')
          return false
        }
        item.deleted = true
        item.isDeleted = true
      }
    },
    isDescEmpty(desc) {
      if (desc === '') {
        return true
      } else {
        return false
      }
    },
    sort(name, order) {
      this.innerValue.otherPurchases = _orderBy(this.activeItems, name, order)
    }
  }
}
</script>

<style lang="scss" scoped>
.partno-input {
  max-width: 3.5em;
}
.itemdesc-input {
  min-width: 20em;
}
.money-input {
  max-width: 6.5em;
}
.total-input {
  min-width: 6.5em;
}
.qty-input {
  max-width: 3.5em;
}
</style>