<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile is-parent is-vertical">
      <article class="tile is-child box detail-page-tile">
        <div class="columns is-gapless">
          <div class="column">
            <p class="title">
              Details
            </p>
          </div>
        </div>
        <div class="field">
          <div class="field-body">
            <div class="field">
              <label class="label">Purchase Order No.</label>
              <div class="control">
                <input class="input is-static"
                  :value="`${innerValue.prefix}${innerValue.purchaseOrderNo}${innerValue.suffix}`"
                  type="text"
                  readonly>
              </div>
            </div>
            <div class="field is-narrow">
              <label class="label">Date</label>
              <v-date-picker v-if="!readOnlyView && !isReceive"
                v-model="selectedPoDate"
                :masks="formats"
                :attributes="attrs"
                :locale="$userInfo.locale"
                @input="selectedPoDate = $filters.fromISOWithCurrentTime($event.toISOString())"
                :timezone="$filters.getIanaTimezone()">
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="field">
                    <div class="control has-icons-left">
                      <input type="text"
                        class="input"
                        placeholder="Quote Date"
                        :value="inputValue"
                        v-on="inputEvents"
                        readonly>
                      <span class="icon is-small is-left">
                        <i class="mdi mdi-calendar mdi-18px" />
                      </span>
                    </div>
                  </div>
                </template>
              </v-date-picker>
              <span v-else>
                <input class="input is-static"
                  :value="$filters.formatDateTimezone(purchaseOrderDate, $userInfo.locale)"
                  readonly>
              </span>
            </div>
          </div>
        </div>

        <div class="is-divider" />
        <div class="field">
          <label class="label">Vendors</label>
          <div v-if="readOnlyView || isReceive"
            class="field-body">
            <input class="input is-static"
              :value="selectedVendor ? selectedVendor.name : ''"
              readonly>
          </div>
          <div v-else
            class="field-body">
            <div class="field has-addons">
              <div class="control is-expanded">
                <multiselect v-if="vendors"
                  v-model="selectedVendor"
                  deselect-label="Can't remove this value"
                  track-by="name"
                  label="name"
                  :class="{'is-danger' : $v.entity.vendorId.$error}"
                  :show-labels="false"
                  :options="vendors"
                  :options-limit="assetFilter.pageSize"
                  :loading="isVendorLoading"
                  :internal-search="false"
                  :searchable="true"
                  :allow-empty="true"
                  @select="selectVendor"
                  @remove="removeVendor"
                  @search-change="getVendorDropdownFilter"
                  placeholder="Select a vendor">
                  <span class="has-text-danger"
                    slot="noResult">Vendor not found.</span>
                </multiselect>
                <span class="help is-danger"
                  v-if="$v.entity && !$v.entity.vendorId.validGuid">Vendor is required.
                </span>
              </div>
              <div class="control">
                <a class="button"
                  :class="vendorButtonClass"
                  @click="onEditAddVendor()">
                  <span class="icon">
                    <i class="mdi mdi-24px"
                      :class="vendorButtonIconClass" />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="field">
          <label class="label">Contact</label>
          <div v-if="readOnlyView || isReceive"
            class="field-body">
            <input class="input is-static"
              :value="selectedContact ? selectedContact.fullname : ''"
              readonly>
          </div>
          <div v-else
            class="field-body">
            <div class="field has-addons">
              <div class="control is-expanded">
                <multiselect v-if="contacts"
                  v-model="selectedContact"
                  deselect-label="Can't remove this value"
                  track-by="contactId"
                  label="fullname"
                  :show-labels="false"
                  :options="contacts"
                  :options-limit="assetFilter.pageSize"
                  :internal-search="false"
                  :searchable="true"
                  :allow-empty="true"
                  @select="selectContact"
                  @remove="removeContact"
                  @search-change="onContactSearchChange"
                  @input.native="captureContactInput"
                  placeholder="select a contact">
                  <span class="has-text-danger"
                    slot="noResult">Contact not found.</span>
                </multiselect>
              </div>
              <div class="control">
                <a class="button"
                  :class="contactButtonClass"
                  @click="onEditAddContact()"
                  :disabled="!hasVendorSelected || readOnlyView">
                  <span class="icon">
                    <i class="mdi mdi-24px"
                      :class="contactButtonIconClass" />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="field">
          <div class="field-body">
            <div class="field">
              <label class="label">Phone</label>
              <div class="control">
                <input class="input is-static"
                  v-model="phone"
                  type="text"
                  placeholder=""
                  readonly>
              </div>
            </div>
            <div class="field">
              <label class="label">Fax</label>
              <div class="control">
                <input class="input is-static"
                  v-model="fax"
                  type="text"
                  placeholder=""
                  readonly>
              </div>
            </div>
            <div class="field">
              <label class="label">ABN</label>
              <div class="control">
                <input class="input is-static"
                  v-model="abn"
                  type="text"
                  placeholder=""
                  readonly>
              </div>
            </div>
          </div>
        </div>
        <div class="is-divider" />
        <div class="field">
          <div class="field-body">
            <div class="field is-narrow quote-width">
              <label class="label">Quote</label>
              <input v-if="innerValue.isNew"
                class="input is-uppercase"
                :class="{'is-danger' : $v.entity.quoteId.$error}"
                v-model="quoteNo"
                @input="searchQuote"
                placeholder="Start typing to search...">
              <span v-else>{{ innerValue.quoteNo }}</span>
              <span v-if="$v.entity && $v.entity.quoteId.isValidQuoteNo && quoteNo !== ''"
                class="help is-success">Valid Quote No.</span>
              <span v-else-if="quoteNo.length > 0"
                class="help is-danger">Invalid Quote No.</span>
            </div>
            <div class="field">
              <label class="label">Order by</label>
              <div v-if="!readOnlyView && !isReceive"
                class="control is-expanded">
                <bulma-autocomplete placeholder="Orderer"
                  v-model="innerValue.orderer"
                  :data="filteredOrderers"
                  :keep-first="true"
                  :loading="false"
                  @input="$v.detailGroup.$touch()"
                  :class-prop="'is-capitalized' + (!$v.entity.orderer.$error ? '' : ' is-danger')" />
                <span class="help is-danger"
                  v-if="$v.entity && !$v.entity.orderer.required">Order By is required.
                </span>
              </div>
              <div v-else>
                {{ innerValue.orderer }}
              </div>
            </div>
            <div class="field is-narrow">
              <label class="label">Parts Check No</label>
              <div v-if="innerValue.partsCheckPurchaseOrderNo"
                class="control">
                <input class="input is-static"
                  :value="`${innerValue.partsCheckPurchaseOrderNo}`"
                  type="text"
                  placeholder=""
                  readonly>
              </div>
              <div v-else
                class="control">
                <input class="input is-static"
                  :value="'Manual PO'"
                  type="text"
                  placeholder=""
                  readonly>
              </div>
            </div>
          </div>
        </div>
        <div class="field">
          <div class="field-body">
            <div class="field">
              <input class="is-checkradio is-info"
                v-model="isDisplayPrice"
                type="checkbox"
                id="price">
              <label for="price">Display Prices on Report</label>
            </div>
            <div class="field">
              <input class="is-checkradio is-info"
                v-model="isDisplayPart"
                type="checkbox"
                id="part">
              <label for="part">Display Part No. on Report</label>
            </div>
          </div>
          <div class="is-divider" />
          <label class="label">Recent Remarks</label>
          <table class="table is-bordered is-striped is-narrow is-fullwidth">
            <thead />
            <tbody>
              <tr v-for="(remark) in displayRemarks"
                :key="remark.remarkId">
                <td class="is-content-width">
                  <span class="tag is-info">{{ $filters.formatDateTimeLocale(remark.remarkDateTime, $userInfo.locale) }}</span>
                </td>
                <td class="is-remark">
                  <div>{{ remark.remarks }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </article>
    </div>
    <div class="tile is-parent is-half">
      <article class="tile is-child box detail-page-tile">
        <p class="title">Summary</p>
        <purchase-order-detail-summary v-model="value"
          :item-summaries="itemSummaries" />
      </article>
    </div>
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import _debounce from 'lodash.debounce'
import _isEmpty from 'lodash/isEmpty'
import Multiselect from 'vue-multiselect'
import Guid from '@/components/Guid'
import { ContactPayload } from '@/classes'
import { EventHubTypes, RemarkTypes, AssetTypes } from '@/enums'
import { DateTimeFiltersMixin } from '@/components/mixins/filters'
import { BulmaAutocomplete } from '@/components/BulmaAutocomplete'
import QuoteService from '@/views/quote/QuoteService'
import VendorService from '@/views/vendor/VendorService'
import QuickInvoiceService from '@/views/quickinvoice/QuickInvoiceService'
import StoreMixin from './storeMixin'
import PurchaseOrderDetailSummary from './PurchaseOrderDetailSummary'
import PurchaseOrderService from './PurchaseOrderService'

export default {
  name: 'PurchaseOrderDetail',
  inject: ['$vv'],
  components: {
    PurchaseOrderDetailSummary,
    Multiselect,
    BulmaAutocomplete
  },
  filters: {},
  mixins: [StoreMixin, DateTimeFiltersMixin],
  props: {
    value: null,
    isReceive: {
      type: Boolean,
      default: false
    },
    displayPart: {
      type: Boolean,
      default: false
    },
    displayPrice: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerValue: _cloneDeep(this.value),
      isVendorLoading: false,
      isContactLoading: false,
      isOrdererLoading: false,
      customerInput: '',
      contactInput: '',
      purchaseOrderDate: null,
      selectedPoDate: null,
      selectedVendor: null,
      selectedContact: null,
      selectedOrderer: null,
      isDisplayPart: this.displayPart,
      isDisplayPrice: this.displayPrice,
      vendorButtonLoading: false,
      contactButtonLoading: false,
      vendors: [],
      orderers: [],
      abn: null,
      vendor: null,
      phone: null,
      fax: null,
      quoteNo: this.value.quoteNo || '',
      contacts: [],
      vendorsName: [],
      assetFilter: {
        id: Guid.empty(),
        name: '',
        pageIndex: 1,
        pageSize: 50
      },
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          }
          // dates: new Date()
        }
      ]
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    hasVendorSelected() {
      return this.selectedVendor && !_isEmpty(this.selectedVendor)
    },
    vendorButtonIconClass() {
      return {
        'mdi-pencil': this.hasVendorSelected,
        'mdi-plus': !this.hasVendorSelected
      }
    },
    vendorButtonClass() {
      return {
        'is-primary': this.hasVendorSelected,
        'is-success': !this.hasVendorSelected,
        'is-loading': this.vendorButtonLoading
      }
    },
    hasContactSelected() {
      return this.selectedContact && !_isEmpty(this.selectedContact)
    },
    contactButtonIconClass() {
      return {
        'mdi-pencil': this.hasContactSelected,
        'mdi-plus': !this.hasContactSelected
      }
    },
    contactButtonClass() {
      return {
        'is-primary': this.hasContactSelected,
        'is-success': !this.hasContactSelected,
        'is-loading': this.contactButtonLoading
      }
    },
    filteredOrderers() {
      return this.orderers.filter((option) => {
        return option.toString().toLowerCase().indexOf(this.innerValue.orderer.toLowerCase()) >= 0
      })
    },
    itemSummaries() {
      return this.innerValue.otherPurchases
        .filter((i) => !i.deleted)
        .concat(
          this.innerValue.sublets.filter((i) => !i.deleted),
          this.innerValue.miscs.filter((i) => !i.deleted),
          this.innerValue.parts.filter((i) => !i.deleted)
        )
    },
    displayRemarks() {
      return this.value.remarks.filter((r) => r.remarkTypeId === RemarkTypes.Remark).slice(0, 5)
    }
  },
  watch: {
    innerValue: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    selectedPoDate(newVal, oldVal) {
      if (newVal) {
        this.innerValue.purchaseOrderDate = newVal.toISOString().split('.')[0] + 'Z'
      } else {
        this.innerValue.purchaseOrderDate = null
      }
    },
    selectedOrderer: {
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.innerValue.orderer = newVal.toLowerCase()
        }
      },
      deep: true
    },
    isDisplayPart(newVal, oldVal) {
      this.$emit('display-part', newVal)
    },
    isDisplayPrice(newVal, oldVal) {
      this.$emit('display-price', newVal)
    },
    'innerValue.quoteId'(newVal, oldVal) {
      this.innerValue.sublets = []
      this.innerValue.miscs = []
      this.innerValue.parts = []
    }
  },
  beforeDestroy() {
    this.$eventHub.$off(EventHubTypes.EntitySaved)
    this.$eventHub.$off(EventHubTypes.EntityReload)
  },
  created() {
    this.innerValue = _cloneDeep(this.value)
    this.assetFilter.id = this.innerValue.vendorId
    this.getAssetDropdown()
    this.getOrdererDropdown()
    this.purchaseOrderDate = new Date(`${this.innerValue.purchaseOrderDate}`)
    this.selectedPoDate = new Date(`${this.innerValue.purchaseOrderDate}`)
  },
  mounted() {
    this.$v.detailGroup.$touch()
    this.$eventHub.$on(EventHubTypes.EntitySaved, () => {
      this.innerValue = _cloneDeep(this.value)
    })
    this.$eventHub.$on(EventHubTypes.EntityReload, () => {
      this.innerValue = _cloneDeep(this.value)
    })
  },
  methods: {
    async getAssetDropdown() {
      this.isVendorLoading = true
      ;[this.vendors, this.contacts] = await Promise.all([
        VendorService.getVendorDropdownProto2(this.assetFilter),
        QuickInvoiceService.getContactDropdown(this.innerValue.vendorId)
      ])
      if (this.assetFilter.id !== Guid.empty()) {
        this.selectedVendor = this.vendors.find((c) => c.id === this.innerValue.vendorId.toUpperCase())
        this.vendor = await VendorService.getEntity(this.innerValue.vendorId)

        this.phone = this.vendor.phones.find((i) => i.type == '0') == null ? null : this.vendor.phones.find((i) => i.type == '0').no
        this.fax = this.vendor.phones.find((i) => i.type == '3') == null ? null : this.vendor.phones.find((i) => i.type == '3').no
        this.abn = this.vendor.abn
      }
      this.selectedContact = null
      if (this.innerValue.contactId !== Guid.empty()) {
        this.selectedContact = this.contacts.find((c) => c.contactId === this.innerValue.contactId)
      }
      // this.selectedContact = this.contacts.find((c) => c.contactId === this.innerValue.contactId)
      this.isVendorLoading = false
      // return this.vendors
    },
    async getVendorsDropdown() {
      this.isVendorLoading = true
      this.vendors = await VendorService.getVendorDropdownProto2(this.assetFilter)
      if (this.assetFilter.id !== Guid.empty()) {
        this.selectedVendor = this.vendors.find((c) => c.id === this.innerValue.vendorId)
      }
      this.isVendorLoading = false
      return this.vendors
    },
    getVendorDropdownFilter: _debounce(async function (query) {
      this.isVendorLoading = true
      this.assetFilter.name = query
      this.vendors = await VendorService.getVendorDropdownProto2(this.assetFilter)
      this.isVendorLoading = false
    }, 500),
    async selectVendor(selected) {
      this.innerValue.vendorId = selected.id
      this.contacts = await QuickInvoiceService.getContactDropdown(selected.id)
      if (selected) {
        this.vendor = await VendorService.getEntity(selected.id)
        this.phone = this.vendor.phones.find((i) => i.type == '0') == null ? null : this.vendor.phones.find((i) => i.type == '0').no
        this.fax = this.vendor.phones.find((i) => i.type == '3') == null ? null : this.vendor.phones.find((i) => i.type == '3').no
        this.abn = selected.abn
      }
      this.selectedContact = null
    },
    removeVendor(removed) {
      this.assetFilter.id = Guid.empty()
      this.innerValue.vendorId = Guid.empty()
      this.innerValue.contactId = Guid.empty()
      this.selectedContact = null
      this.contacts = []
      this.getAssetDropdown()
    },
    selectContact(selected) {
      this.innerValue.contactId = selected.contactId
    },
    removeContact(removed) {
      this.innerValue.contactId = Guid.empty()
    },
    async getGLAccountDropdown() {
      this.isGlAccountLoading = true
      await VendorService.getGlAccountListComboProto().then((result) => {
        this.glAccountListCombo = result
      })
      this.isGlAccountLoading = false
    },
    onContactSearchChange(query) {
      this.isContactLoading = true
      if (query && this.selectedContact) {
        this.selectedContact = null
      }
      this.isContactLoading = false
    },
    captureContactInput(event) {
      this.contactInput = event.target.value
    },
    onEditAddContact(event) {
      if (!this.hasVendorSelected) {
        return false
      }
      this.contactButtonLoading = true
      this.setContactReturnRoute(this.$route)
      if (this.hasContactSelected) {
        this.editStoreContact(this.selectedContact.contactId)
      } else {
        const payload = new ContactPayload(this.contactInput, this.innerValue.vendorId, AssetTypes.Vendor, this.selectedVendor.name)
        this.addStoreContact(payload)
      }
    },
    onEditAddVendor() {
      this.vendorButtonLoading = true
      this.setVendorReturnRoute(this.$route)
      if (this.hasVendorSelected) {
        this.editStoreVendor(this.selectedVendor.id)
      } else {
        this.addStoreVendor(this.assetFilter.name)
      }
    },
    async getOrdererDropdown() {
      this.isOrdererLoading = true
      this.orderers = await PurchaseOrderService.getOrderersDropdown()
      this.selectedOrderer = this.orderers.find((i) => i.toLowerCase() === this.innerValue.orderer)
      this.isOrdererLoading = false
    },
    searchQuote: _debounce(async function () {
      this.removeQuoteVehicle()
      this.removeQuoteCustomer()
      this.removeQuoteInsurer()
      if (this.quoteNo) {
        this.innerValue.quoteId = await QuoteService.getQuoteIdByNo(this.quoteNo)
        if (this.innerValue.quoteId !== Guid.empty()) {
          let quote = await QuoteService.getEntity(this.innerValue.quoteId)
          this.innerValue.quoteNo = this.quoteNo
          this.innerValue.customerId = quote.customerId
          this.innerValue.insurerId = quote.insurerId
          this.getQuoteVehicle({ id: quote.vehicleId, refresh: true })
          this.getQuoteCustomer({ id: quote.customerId, refresh: true })
          this.getQuoteInsurer({ id: quote.insurerId, refresh: true })
        } else {
          this.innerValue.quoteNo = this.quoteNo
        }
      } else {
        this.innerValue.quoteId = Guid.empty()
        this.innerValue.quoteNo = ''
      }
    }, 500),
    poDateInputEvent(event) {
      this.selectedPoDate = this.$filters.fromISOWithCurrentTime(event.toISOString())
    }
  }
}
</script>

<style lang="scss" scoped>
.is-divider {
  margin: 1.2em 0;
}
.quote-width {
  width: 150px;
}
</style>
