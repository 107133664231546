<template>
  <app-side-menu :min-width="18"
    :fixed-menu="false">
    <aside class="menu"
      slot="side-menu">
      <p class="menu-label">
        <span class="title is-6">
          Purchase Order: {{ entityName }}
        </span>
      </p>
      <ul class="menu-list">
        <li>
          <router-link :to="{ name: routeTypes.PurchaseOrderDetail.name, params: { purchaseOrderId : $route.params.purchaseOrderId }, query: $route.query }"
            active-class="is-active"
            exact>
            <span :class="{ 'has-badge-danger has-badge-small has-badge-rounded has-badge-offset2': $v.detailGroup.$error }"
              :data-badge="$v.detailGroup.$error ? '' : null">
              Detail Info
            </span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: routeTypes.PurchaseOrderVehicleCustomerInsurer.name, params: { purchaseOrderId : $route.params.purchaseOrderId }, query: $route.query }"
            active-class="is-active"
            event=""
            :class="{ 'is-disabled': !enableRoutes}"
            @click.native.prevent="goToRoute({ name: routeTypes.PurchaseOrderVehicleCustomerInsurer.name, params: { purchaseOrderId : $route.params.purchaseOrderId }, query: $route.query })"
            exact>
            <span>
              Other Info
            </span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: routeTypes.PurchaseOrderRemarks.name, params: { purchaseOrderId : $route.params.purchaseOrderId }, query: $route.query }"
            active-class="is-active"
            exact>
            <span>
              Remarks
            </span>
          </router-link>
        </li>
      </ul>
      <p class="menu-label">
        Items
      </p>
      <ul class="menu-list">
        <li>
          <router-link :to="{ name: routeTypes.PurchaseOrderOverview.name, params: { purchaseOrderId : $route.params.purchaseOrderId }, query: $route.query }"
            active-class="is-active"
            exact>
            <span>
              Overview
            </span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: routeTypes.PurchaseOrderOtherPurchases.name, params: { purchaseOrderId : $route.params.purchaseOrderId }, query: $route.query }"
            active-class="is-active"
            exact>
            <span>
              <span class="has-badge-child-offset3">Other Purchases</span>
              <span v-show="otherPurchases.length"
                class="has-badge-rounded has-badge-medium"
                :class="[$v.entity.otherPurchases.$error ? 'has-badge-danger' : 'has-badge-info']"
                :data-badge="otherPurchases.length" />
            </span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: routeTypes.PurchaseOrderSublet.name, params: { purchaseOrderId : $route.params.purchaseOrderId }, query: $route.query }"
            active-class="is-active"
            event=""
            :class="{ 'is-disabled': !enableRoutes}"
            @click.native.prevent="goToRoute({ name: routeTypes.PurchaseOrderSublet.name, params: { purchaseOrderId : $route.params.purchaseOrderId }, query: $route.query })"
            exact>
            <span>
              <span class="has-badge-child-offset3">Sublet</span>
              <span v-show="sublets.length"
                class="has-badge-rounded has-badge-medium has-badge-info"
                :data-badge="sublets.length" />
            </span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: routeTypes.PurchaseOrderMisc.name, params: { purchaseOrderId : $route.params.purchaseOrderId }, query: $route.query }"
            active-class="is-active"
            event=""
            :class="{ 'is-disabled': !enableRoutes}"
            @click.native.prevent="goToRoute({ name: routeTypes.PurchaseOrderMisc.name, params: { purchaseOrderId : $route.params.purchaseOrderId }, query: $route.query })"
            exact>
            <span>
              <span class="has-badge-child-offset3">Misc</span>
              <span v-show="miscs.length"
                class="has-badge-rounded has-badge-medium has-badge-info"
                :data-badge="miscs.length" />
            </span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: routeTypes.PurchaseOrderParts.name, params: { purchaseOrderId : $route.params.purchaseOrderId }, query: $route.query }"
            active-class="is-active"
            event=""
            :class="{ 'is-disabled': !enableRoutes}"
            @click.native.prevent="goToRoute({ name: routeTypes.PurchaseOrderParts.name, params: { purchaseOrderId : $route.params.purchaseOrderId }, query: $route.query })"
            exact>
            <span>
              <span class="has-badge-child-offset3">Parts</span>
              <span v-show="parts.length"
                class="has-badge-rounded has-badge-medium has-badge-info"
                :data-badge="parts.length" />
            </span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: routeTypes.PurchaseOrderOrderReceipt.name, params: { purchaseOrderId : $route.params.purchaseOrderId }, query: $route.query }"
            active-class="is-active"
            event=""
            :class="{ 'is-disabled': !enableReceiptRoutes}"
            @click.native.prevent="goToReceiptRoute({ name: routeTypes.PurchaseOrderOrderReceipt.name, params: { purchaseOrderId : $route.params.purchaseOrderId }, query: $route.query })"
            exact>
            <span>
              <span class="has-badge-child-offset3">Order Receipt</span>
              <span v-show="orderReceipts.length"
                class="has-badge-rounded has-badge-medium"
                :class="[$v.entity.orderReceipts.$error ? 'has-badge-danger' : 'has-badge-info']"
                :data-badge="orderReceipts.length" />
            </span>
          </router-link>
        </li>
      </ul>
    </aside>
  </app-side-menu>
</template>

<script>
import AppSideMenu from '@/components/AppSideMenu'
import PurchaseOrderRoutes from './route-types'
import Guid from '@/components/Guid'

export default {
  name: 'PurchaseOrderSideMenu',
  inject: ['$vv'],
  components: {
    AppSideMenu
  },
  props: {
    value: null,
    entityName: {
      type: String,
      default: null
    }
  },
  data() {
    return {}
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    routeTypes() {
      return PurchaseOrderRoutes
    },
    enableRoutes() {
      return Guid.validGuid(this.value.quoteId) && this.value.quoteId !== Guid.empty()
    },
    enableReceiptRoutes() {
      return !this.value.isNew && (this.poItems.length > 0 || this.orderReceipts.length > 0)
    },
    otherPurchases() {
      return this.value.otherPurchases.filter(i => !i.deleted)
    },
    miscs() {
      return this.value.miscs.filter(i => !i.deleted)
    },
    sublets() {
      return this.value.sublets.filter(i => !i.deleted)
    },
    parts() {
      return this.value.parts.filter(i => !i.deleted)
    },
    orderReceipts() {
      return this.value.orderReceipts.filter(i => !i.isDeleted)
    },
    poItems() {
      return this.parts.concat(this.miscs, this.sublets, this.otherPurchases)
    }
  },
  mounted() {},
  methods: {
    goToRoute(route) {
      if (this.enableRoutes) {
        // console.log(route)
        this.$router.push(route)
      }
    },
    goToReceiptRoute(route) {
      if (this.enableReceiptRoutes) {
        // console.log(route)
        this.$router.push(route)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.is-disabled {
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
  // pointer-events: none;
  a {
    display: inline-block;
    // pointer-events: none;
    text-decoration: none;
  }
}

span.superscript {
  vertical-align: super;
  font-size: smaller;
}

span.has-badge-child {
  margin-right: 1em;
}

.has-badge-icon {
  &::after {
    font-family: 'Material Design Icons';
    content: '';
    //content: attr(data-badge);
    // content: attr(data-test);
  }
}

.has-badge-icon-check {
  &::after {
    font-family: 'Material Design Icons';
    content: '\f12c';
    //content: attr(data-test);
  }
}

.has-badge-icon-exclamation {
  &::after {
    font-family: 'Material Design Icons';
    content: '\f205';
  }
}
</style>
