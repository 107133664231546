import { render, staticRenderFns } from "./PurchaseOrderDetailSummary.vue?vue&type=template&id=532e4abc&scoped=true"
import script from "./PurchaseOrderDetailSummary.vue?vue&type=script&lang=js"
export * from "./PurchaseOrderDetailSummary.vue?vue&type=script&lang=js"
import style0 from "./PurchaseOrderDetailSummary.vue?vue&type=style&index=0&id=532e4abc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "532e4abc",
  null
  
)

export default component.exports