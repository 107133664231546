<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile is-parent is-vertical">
      <article class="tile is-child box detail-page-tile">
        <div class="columns is-gapless">
          <div class="column">
            <p class="title">Sublet</p>
            <subtitle-totals :labels="['Total']"
              :totals="[subletsTotal]"
              :shows="[true]" />
          </div>
          <div v-if="!readOnlyView"
            class="column">
            <div class="control is-pulled-right">
              <div v-if="!isLockPurchaseOrder"
                class="buttons">
                <button class="button is-primary tooltip is-tooltip-topright"
                  :disabled="isLockPurchaseOrder"
                  data-tooltip="Add new sublet"
                  @click="toggleQuotedSublets()">
                  <span class="icon">
                    <i class="mdi"
                      :class="{ 'mdi-plus' : !isQuotedSubletsActive, 'mdi-minus' : isQuotedSubletsActive }" />
                  </span>
                  <span>{{ isQuotedSubletsActive ? 'Close' : 'Add' }}</span>
                </button>
              </div>
              <div v-else>
                <order-lock-notification />
              </div>
            </div>
          </div>
        </div>
        <quoted-sublets v-if="isQuotedSubletsActive"
          v-model="innerValue.sublets"
          :quote-id="innerValue.quoteId"
          :purchase-order-id="innerValue.purchaseOrderId"
          :read-only-view="readOnlyView"
          @item-added="onItemAdded"
          @item-deleted="deleteAllItems" />
        <div v-if="isQuotedSubletsActive"
          class="is-divider" />
        <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
          :columns="columns"
          :page-index="1"
          :page-size="100"
          :total-rows="activeItems.length"
          :show-pagination="false"
          :is-loading="isTableLoading"
          :sort-column="sortColumn"
          :sort-order="sortOrder"
          @sort="sort">
          <tr v-for="(item, index) in activeItems"
            :key="item.purchaseOrderItemId"
            :class="{ 'is-selected' : selectedRow === index }">
            <td class="itemno-input has-vertical-middle">
              {{ item.itemNo }}
              <span v-if="item.quoteItemDeleted"
                class="icon has-text-danger tooltip is-tooltip-danger"
                data-tooltip="Item has been deleted in quote">
                <i class="mdi mdi-alert mdi-18px" />
              </span>
              <span v-if="(item.quantity + item.otherPoOrderedQty) > item.quotedItemQty"
                style="padding-right: 3px"
                class="icon has-text-warning tooltip is-tooltip-warning has-vertical-middle"
                data-tooltip="This item has been ordered in other Purchase Order">
                <i class="mdi mdi-alert mdi-18px" />
              </span>
            </td>
            <td class="has-vertical-middle itemdesc-input">
              <input v-if="!readOnlyView"
                class="input"
                :disabled="isLockPurchaseOrder"
                placeholder="Item Description"
                v-model="item.description">
              <span v-else>{{ item.description }}</span>
            </td>
            <td class="has-vertical-middle has-text-right money-input">
              <vue-numeric v-if="!readOnlyView"
                class="input has-text-right"
                v-model.number="item.dealerCost"
                :min="0"
                :disabled="isLockPurchaseOrder"
                :precision="2" />
              <span v-else>{{ item.dealerCost | formatNumber($userInfo.locale) }}</span>
            </td>
            <td class="has-vertical-middle has-text-right">{{ item.receivedQty - item.returnQty }}</td>
            <td class="has-vertical-middle has-text-centered is-content-width">
              <button class="button is-danger is-small is-inverted"
                @click="deleteItem(item, index)"
                :disabled="readOnlyView || isLockPurchaseOrder">
                <span class="icon is-medium">
                  <i class="mdi mdi-delete mdi-24px" />
                </span>
              </button>
            </td>
          </tr>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <span icon="icon is-large">
                  <i class="mdi mdi-48px mdi-emoticon-sad" />
                </span>
                <p>Nothing</p>
              </div>
            </section>
          </template>
          <template slot="footer">
            <tr>
              <th class="has-text-right has-text-success"
                :colspan="totalColSpan">Totals</th>
              <th class="has-text-right has-text-success"
                :class="[ subletsTotal >= 0 ? 'has-text-success' : 'has-text-danger' ]">{{ subletsTotal | formatCurrency($userInfo.locale) }}</th>
            </tr>
          </template>
        </bulma-table>
      </article>
    </div>
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import _orderBy from 'lodash/orderBy'
import BulmaTable from '@/components/BulmaTable'
import QuotedSublets from './components/QuotedSublets'
import VueNumeric from '@/components/VueNumeric'
import SubtitleTotals from '@/components/SubtitleTotals'
import { EventHubTypes } from '@/enums'
import { ItemsToOrderColumns } from './columns'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import OrderLockNotification from './components/PurchaseOrderLockNotification'

const title = 'Sublet'

export default {
  name: 'PurchaseOrderSublet',
  components: {
    BulmaTable,
    QuotedSublets,
    VueNumeric,
    QuotedSublets,
    SubtitleTotals,
    OrderLockNotification
  },
  filters: {},
  mixins: [NumberFiltersMixin],
  props: {
    value: null,
    readOnlyView: {
      type: Boolean
    },
    isReceive: {
      type: Boolean,
      default: false
    },
    isLockPurchaseOrder: {
      type: Boolean
    }
  },
  data() {
    return {
      innerValue: _cloneDeep(this.value),
      quotedItem: null,
      itemToOrder: null,
      isTableLoading: false,
      selectedRow: false,
      isQuotedSubletsActive: false,
      sortColumn: '',
      sortOrder: ''
    }
  },
  computed: {
    columns() {
      return ItemsToOrderColumns
    },
    activeItems() {
      return this.innerValue.sublets.filter(i => !i.deleted)
    },
    subletsTotal() {
      return roundAwayFromZero(
        this.activeItems.reduce(function(total, item) {
          return total + item.dealerCost
        }, 0),
        2
      )
    },
    totalColSpan() {
      return this.columns.length - (this.$userInfo.isSupportUser ? 3 : 4)
    }
  },
  watch: {
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  beforeDestroy() {
    this.$eventHub.$off(EventHubTypes.EntitySaved)
    this.$eventHub.$off(EventHubTypes.EntityReload)
  },
  created() {},
  mounted() {
    this.$eventHub.$on(EventHubTypes.EntitySaved, () => {
      this.innerValue = _cloneDeep(this.value)
      this.isQuotedSubletsActive = false
    })
    this.$eventHub.$on(EventHubTypes.EntityReload, () => {
      this.innerValue = _cloneDeep(this.value)
      this.isQuotedSubletsActive = false
    })
  },
  methods: {
    toggleQuotedSublets() {
      this.isQuotedSubletsActive = !this.isQuotedSubletsActive
    },
    onItemAdded(newItem) {
      this.innerValue.sublets.splice(this.innerValue.sublets.length, 1, newItem)
    },
    deleteItem(item, index) {
      if (item.isNew) {
        const itemIndex = this.innerValue.sublets
          .map(function(obj) {
            return obj.purchaseOrderItemId
          })
          .indexOf(item.purchaseOrderItemId)
        if (itemIndex >= 0) {
          this.innerValue.sublets.splice(itemIndex, 1)
        }
      } else {
        let netRecQty = item.receivedQty - item.returnQty
        if (netRecQty > 0) {
          this.$notification.openNotificationWithType('danger', title, 'This item has been received. You can\'t delete it.')
          return false
        }
        item.deleted = true
        item.isDeleted = true
      }
    },
    deleteAllItems() {
      for (var i = 0; i < this.innerValue.sublets.length; i++) {
        const itemIndex = this.innerValue.sublets
          .map(function(obj) {
            return obj.purchaseOrderItemId
          })
          .indexOf(this.innerValue.sublets[i].purchaseOrderItemId)
        if (itemIndex >= 0) {
          if (this.innerValue.sublets[itemIndex].receivedQty - this.innerValue.sublets[itemIndex].returnQty <= 0) {
            this.innerValue.sublets[itemIndex].isDeleted = true
            this.innerValue.sublets[itemIndex].deleted = true
          }
        }
      }

      var items = this.innerValue.sublets
      items.reduce(function(list, item, index) {
      if (item.isDeleted && item.isNew) list.push(index)
        return list
      }, []).reverse().forEach(function(index) {
        items.splice(index,1)
      })

      this.innerValue.sublets = items
    },
    sort(name, order) {
      this.innerValue.sublets = _orderBy(this.activeItems, name, order)
    }
  }
}
</script>

<style lang="scss" scoped>
.itemno-input {
  min-width: 2.5em;
}
.itemdesc-input {
  min-width: 20em;
}
.money-input {
  max-width: 50px;
}
</style>