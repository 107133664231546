<template>
  <div>
    <app-detail-header :show-toggle="true"
      :show-new="false"
      :show-print="entity && !entity.isNew"
      :show-delete="entity && !entity.deleted && !entity.isNew && !readOnlyView && !isReceive"
      :show-save="entity && !entity.deleted && !readOnlyView"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <purchase-order-side-menu v-if="entity"
          v-model="entity"
          ref="sideMenu"
          :entity-name="entity.prefix + entity.purchaseOrderNo + entity.suffix" />
      </div>
      <div class="column">
        <router-view v-if="entity"
          v-model="entity"
          ref="currentChild"
          :is-tiled="false"
          :key="$route.fullPath"
          :route-name="$route.name"
          :vehicle="quoteVehicle"
          :customer="quoteCustomer"
          :insurer="quoteInsurer"
          :is-receive="isReceive"
          :read-only-view="readOnlyView"
          :is-lock-purchase-order="isLockPurchaseOrder"
          @display-part="isDisplayPart"
          @display-price="isDisplayPrice" />
      </div>
    </div>
    <unsaved-modal :active.sync="isUnsavedModalActive"
      @close="closeModal()"
      @skipSave="skipSave()"
      @saveContinue="saveContinue()">
      <p slot="text-title">Unsaved Changes</p>
      <p slot="text-content">There are unsaved changes. Please select action below</p>
    </unsaved-modal>
    <save-conflict-modal :active.sync="isSaveConflictModalActive"
      @close="closeModal()"
      @reload="reloadData()">
      <p slot="text-title">Change conflict</p>
      <p slot="text-content">The data on the server is newer than the local copy. Please reload local data.</p>
    </save-conflict-modal>
    <confirm-modal v-if="entity"
      :active.sync="isConfirmModalActive"
      @ok="deleteEntity(true)"
      @cancel="deleteEntity(false)"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Delete Purchase Order</p>
      <p slot="text-content">
        Purchase Order
        <span class="has-text-primary has-text-weight-bold">{{ `${entity.prefix}${entity.purchaseOrderNo}${entity.suffix}` }}</span> will be deleted. Continue?
      </p>
    </confirm-modal>
    <confirm-modal v-if="entity"
      :active.sync="isVendorAbnActive"
      @ok="saveContinueWithoutAbn()"
      @cancel="closeModal"
      :ok-text="'OK'"
      :cancel-text="'Cancel'">
      <p slot="text-title">With-Hold Tax</p>
      <p slot="text-content">
        This vendor does not have an ABN. You will be required to with hold 49% of the invoice. Ok to Continue?
      </p>
    </confirm-modal>
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import _debounce from 'lodash.debounce'
import AppDetailHeader from '@/components/AppDetailHeader'
import Guid from '@/components/Guid'
import HttpStatus from '@/components/http-status'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { Emailer } from '@/classes'
import { KeyValuePairModel } from '@/classes/viewmodels'
import { UnsavedModal, SaveConflictModal, ConfirmModal } from '@/components/BulmaModal'
import { AppHeaderButtonTypes, EventHubTypes, RemarkTypes } from '@/enums'
import _isEmpty from 'lodash/isEmpty'
import VendorRoutes from '@/views/vendor/route-types'
import ContactRoutes from '@/views/contact/route-types'
import PurchaseOrderRoutes from './route-types'
import PurchaseOrderService from './PurchaseOrderService'
import PurchaseOrderSideMenu from './PurchaseOrderSideMenu'
import PurchaseOrderValidation from './PurchaseOrderValidation'
import StoreMixin from './storeMixin'

const title = 'Purchase Order'

export default {
  name: 'PurchaseOrderView',
  components: {
    AppDetailHeader,
    [PurchaseOrderSideMenu.name]: PurchaseOrderSideMenu,
    UnsavedModal,
    SaveConflictModal,
    ConfirmModal
  },
  filters: {},
  mixins: [PurchaseOrderValidation, StoreMixin],
  props: {
    returnUrl: {
      type: String,
      default: ''
    }
  },
  data: () => {
    return {
      entity: null,
      isUnsavedModalActive: false,
      isSaveConflictModalActive: false,
      isConfirmModalActive: false,
      isVendorAbnActive: false,
      isSkipSave: false,
      isSaveContinue: false,
      isSave: false,
      displayPart: 'FALSE',
      displayPrice: 'FALSE',
      toRoute: null // stores "to" route of beforeRouteLeave
    }
  },
  computed: {
    validateError() {
      return this.$v.$error || this.$v.detailGroup.$error || this.$v.receiptGroup.$error
    },
    isReceive() {
      if (this.entity.orderReceipts.length > 0 && this.entity.orderReceipts.some((i) => !i.isNew)) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    entity: {
      handler: _debounce(function (newVal) {
        if (newVal) {
          this.$forceUpdate()
          this.saveSnapshot(_cloneDeep(this.entity))
        }
      }, 500),
      deep: true
    }
  },
  beforeDestroy() {
    this.$eventHub.$off(EventHubTypes.EntitySaved)
    if (_isEmpty(this.returnRoute)) {
      this.setReadOnlyView(false)
    }
  },
  async created() {
    this.getEntity()
    if (this.returnUrl) {
      this.persistQueries()
      this.replaceRoute(this.$route.params.purchaseOrderId)
    }
  },
  mounted() {
    this.$eventHub.$on(EventHubTypes.EntitySaved, () => {
      this.innerValue = _cloneDeep(this.value)
    })
  },
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          break
        case AppHeaderButtonTypes.Print:
          this.print()
          break
        case AppHeaderButtonTypes.Delete:
          this.checkPartCheckOrder()
          break
        case AppHeaderButtonTypes.Save:
          if (this.snapshotDiff) {
            this.isSave = true
            this.checkVendorAbn()
          } else {
            this.save()
          }
          break
        case AppHeaderButtonTypes.Cancel:
          // if (!this.snapshotDiff || this.readOnlyView) {
          //   this.cancel()
          // } else {
          //   this.isBack = true
          //   this.checkVendorAbn()
          // }
          this.isSave = false
          this.cancel()
          break
      }
    },
    async getEntity(reset = false) {
      this.$showSpinner()
      if (reset) {
        this.clearSessionStorage()
        this.clearSnapshots(this.$route.params.purchaseOrderId)
      }
      if (!this.currentSnapshot) {
        await this.getStoreItem(this.$route.params.purchaseOrderId)
      }
      this.entity = _cloneDeep(this.currentSnapshot)

      if (Guid.validGuid(this.entity.vehicleId)) {
        // Get and store vehicle in vuex store quotes/quoteVehicle
        this.getQuoteVehicle({ id: this.entity.vehicleId, refresh: true })
      }
      if (Guid.validGuid(this.entity.customerId)) {
        // Get and store vehicle in vuex store quotes/quoteVehicle
        this.getQuoteCustomer({ id: this.entity.customerId, refresh: true })
      }
      if (Guid.validGuid(this.entity.insurerId)) {
        // Get and store vehicle in vuex store quotes/quoteVehicle
        this.getQuoteInsurer({ id: this.entity.insurerId, refresh: true })
      }
      this.$hideSpinner()
    },
    async save(isDelete) {
      if (this.validateError && !isDelete) {
        this.$notification.openNotificationWithType('danger', title, 'Validation errors. Please fix before saving')
        return false
      }

      try {
        this.$showSpinner('Saving...')
        let response
        this.saveSnapshot(_cloneDeep(this.entity))
        if (this.entity.isNew) {
          response = await PurchaseOrderService.postEntity(this.entity)
        } else if (this.snapshotDiff) {
          response = await PurchaseOrderService.putEntity(_cloneDeep(this.entity), this.snapshotDiff)
        } else {
          this.$notification.openNotificationWithType('warning', title, 'No changes. Not saved')
        }
        this.$hideSpinner()
        if (this.isSaveContinue) {
          this.$notification.openNotificationWithType('success', title, isDelete ? `${title} deleted` : 'Save successful')
        } else if (response && response.status === HttpStatus.NO_CONTENT) {
          await this.getEntity(true)
          this.$notification.openNotificationWithType('success', title, isDelete ? `${title} deleted` : 'Save successful')
        } else if (response && response.status === HttpStatus.CREATED) {
          await this.$router.replace({
            name: this.$route.name,
            params: { purchaseOrderId: response.data.purchaseOrderId }
          })
          await this.getEntity(true)
          this.$notification.openNotificationWithType('success', title, `New ${title} added`)
        }

        if (!this.isSaveContinue) {
          this.$eventHub.$emit(EventHubTypes.EntitySaved)
        }
        return true
      } catch (e) {
        this.$hideSpinner()
        if (e.response.request && e.response.request.status === HttpStatus.CONFLICT) {
          this.isSaveConflictModalActive = true
        } else {
          this.$notification.openMessageXhrError(title, e)
        }
        return false
      }
    },
    checkPartCheckOrder() {
      if (this.entity.partsCheckPurchaseOrderNo !== '') {
        this.$notification.openNotificationWithType('danger', title, 'This Purchase Order cannot be deleted because it is created by PartsCheck.com.au.')
        return false
      } else {
        this.isConfirmModalActive = true
      }
    },
    async checkVendorAbn(value) {
      let found = this.entity.orderReceipts.some((i) => i.abn === '' && !i.isDeleted)
      if (found) {
        this.isVendorAbnActive = true
      } else {
        if (this.isSaveContinue) {
          this.isSaveContinue = await this.save()
          if (this.isSaveContinue) {
            this.cancel()
          }
        } else if (this.isSave) {
          this.save()
        } else {
          this.cancel()
        }
      }
    },
    deleteEntity(confirmDelete) {
      this.isConfirmModalActive = false
      if (confirmDelete) {
        this.entity.isDelete = true
        this.entity.deleted = true
        // save snapshots immediately not waiting for debounce
        this.save(true)
      }
    },
    async saveContinueWithoutAbn() {
      this.isVendorAbnActive = false
      if (this.isSaveContinue) {
        this.isSaveContinue = await this.save()
        if (this.isSaveContinue) {
          this.cancel()
        }
      } else if (this.isSave) {
        this.save()
      } else {
        this.cancel()
      }
    },
    saveContinue() {
      this.isUnsavedModalActive = false
      this.isSaveContinue = true
      this.isSave = true
      this.checkVendorAbn()
    },
    cancel() {
      const returnUrl = sessionStorage.getItem(`${this.$userInfo.sessionId}|purchaseorder|returnUrl`)
      this.saveSnapshot(_cloneDeep(this.entity))
      if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue && !this.readOnlyView) {
        this.isUnsavedModalActive = true
      } else {
        if (this.returnUrl || returnUrl) {
          this.clearSessionStorage()
          this.clearSnapshots(this.entity.purchaseOrderId)
          this.$router.push(this.returnUrl)
        } else if (this.toRoute) {
          this.$router.push(this.toRoute.fullPath)
        } else if (this.returnRoute && !_isEmpty(this.returnRoute)) {
          this.$router.push(this.returnRoute.fullPath)
        } else {
          this.$router.push({
            name: PurchaseOrderRoutes.PurchaseOrderListView.name
          })
        }
      }
    },
    closeModal() {
      this.isUnsavedModalActive = false
      this.isSaveConflictModalActive = false
      this.isVendorAbnActive = false
      this.isSaveContinue = false
    },
    skipSave() {
      this.isUnsavedModalActive = false
      this.isSkipSave = true
      this.cancel()
    },
    async reloadData() {
      this.isSaveConflictModalActive = false
      this.isSaveContinue = false
      await this.getEntity(true)
      this.$eventHub.$emit(EventHubTypes.EntityReload)
    },
    isDisplayPart(value) {
      this.displayPart = value.toString().toUpperCase()
    },
    isDisplayPrice(value) {
      this.displayPrice = value.toString().toUpperCase()
    },
    async print() {
      const params = {
        PONO: this.entity.prefix + this.entity.purchaseOrderNo + this.entity.suffix,
        COMPANYNAME: encodeURIComponent(this.$userInfo.companyName),
        REPORTTITLE: 'PURCHASE ORDER',
        COMPANYID: this.$userInfo.companyId,
        DISPLAYPART: this.displayPart,
        DISPLAYPRICE: this.displayPrice,
        VendorID: this.entity.vendorId
      }
      // Create KeyValuePairs and add to vuex for later retrieval by PrintPreviewEx component
      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)

      const reportName = `${this.entity.prefix}${this.entity.purchaseOrderNo}${this.entity.suffix}`

      const assetEmail = await PurchaseOrderService.getAssetEmail(this.entity.vendorId)

      const emailer = new Emailer()
      if (assetEmail) {
        emailer.toEmails.push(assetEmail)
      }
      emailer.assetId = this.entity.purchaseOrderId
      emailer.remark = `Purchase Order ${this.entity.prefix}${this.entity.purchaseOrderNo}${this.entity.suffix}`
      emailer.remarkTypeId = RemarkTypes.Remark
      emailer.subject = `Purchase Order ${this.entity.prefix}${this.entity.purchaseOrderNo}${this.entity.suffix}`
      emailer.reportName = reportName.replace(/[/]/g, '_')
      this.addEmailer(emailer)
      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: 'AT_PurchaseOrder' },
        query: { parameterId: this.$guid.newGuid(), emailerId: emailer.id }
      })
    },
    clearSessionStorage() {
      this.removeQueries()
    },
    persistQueries() {
      if (this.returnUrl) {
        sessionStorage.setItem(`${this.$userInfo.sessionId}|purchaseorder|returnUrl`, this.returnUrl)
      }
    },
    removeQueries() {
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|purchaseorder|returnUrl`)
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|purchaseorder|type`)
    },
    clearRouteMeta() {
      // Reset returnUrl meta in case user clicked on other V2
      if (this.$route.meta.returnUrl) {
        this.$route.meta.returnUrl = null
      }
    }
  },
  beforeRouteLeave: async function (to, from, next) {
    this.saveSnapshot(_cloneDeep(this.entity))
    const isEditVendorContact = to.name === VendorRoutes.VendorDetail.name || to.name === ContactRoutes.ContactDetail.name
    if (isEditVendorContact) {
      next()
    } else if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue) {
      this.$router.replace(from.path)
      this.toRoute = to
      this.isUnsavedModalActive = true
    } else {
      // Clean vuex store for now
      this.clearSnapshots(this.entity.purchaseOrderId)
      this.clearSessionStorage()
      this.clearRouteMeta()
      if (to.name !== PrintPreviewRoutes.PrintPreview.name) {
        this.setReturnRoute({})
      }
      next()
    }
  }
}
</script>