<template>
  <div class="notification is-danger is-light py-2 is-flex is-align-items-center">
    <i class="mdi mdi-alert-octagram mdi-30px pr-1" />
    <span class="has-text-weight-bold">Please go to PartsCheck to make changes to the order items</span>
  </div>
</template>

<script>
export default {
  name: 'PurchaseOrderLockNotification',
  components: {},
  mixins: [],
  props: {},
  data: () => {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {},
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  }
}
</script>

<style lang="scss" scoped>
</style>