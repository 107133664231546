<template>
  <div>
    <div class="columns is-gapless">
      <div class="column">
        <div class="field has-addons">
          <div class="control has-icons-left has-icons-right">
            <input class="input"
              v-model="filter.query"
              type="text"
              placeholder="Start typing to filter"
              @input="search">
            <span class="icon is-left">
              <i class="mdi mdi-magnify mdi-18px" />
            </span>
          </div>
          <div class="control">
            <a class="button is-primary is-outlined tooltip"
              data-tooltip="Reset search"
              @click="reset()">
              <span class="icon">
                <i class="mdi mdi-close mdi-24px" />
              </span>
            </a>
          </div>
        </div>
      </div>
      <div class="column is-narrow">
        <div class="field is-horizontal is-pulled-right">
          <div class="field-body">
            <p class="buttons">
              <a
                class="button tooltip"
                @click="setSelectedAll(true)"
                :disabled="isLockPurchaseOrder"
                data-tooltip="Add all items">
                <span class="icon has-text-success">
                  <i class="mdi mdi-select-all mdi-24px" />
                </span>
              </a>
              <a v-show="selectedItems.length"
                class="button tooltip"
                @click="setSelectedAll(false)"
                :disabled="isLockPurchaseOrder"
                data-tooltip="Delete all items">
                <span class="icon has-text-grey">
                  <i class="mdi mdi-select-off mdi-24px" />
                </span>
              </a>
            </p>
          </div>
        </div>
        <div v-if="isLockPurchaseOrder">
          <order-lock-notification />
        </div>
      </div>
    </div>
    <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
      :columns="columns"
      :page-index="filter.pageIndex"
      :page-size="filter.pageSize"
      :total-rows="totalRows"
      :is-loading="isTableLoading"
      :sort-column="filter.sortColumn"
      :sort-order="filter.sortOrder"
      :draggable="false"
      @pageChanged="onPageChange"
      @sort="sort">
      <tr v-for="(item, index) in pagedActiveItems"
        :key="item.quoteItemId"
        @click="highlightSelected(index, $event)"
        @dblclick="addNewItem(item, true)"
        :class="{ 'is-selected' : selectedRow === index }">
        <td class="itemno-input">{{ item.itemNo }}</td>
        <td>{{ item.itemDesc }}</td>
        <td class="has-vertical-middle has-text-right">{{ item.otherPoOrderedQty }}</td>
        <td class="has-vertical-middle">{{ item.otherOrderedPo }}</td>
        <td class="has-vertical-middle has-text-right">
          <span
            class="icon tooltip"
            :data-tooltip="`Received: ${item.quantityReceived} Returned: ${item.quantityReturned}`">
            {{ item.quantityInStock }}
          </span>
        </td>
        <td class="has-vertical-middle has-text-right">{{ item.value | formatNumber($userInfo.locale) }}</td>
        <td class="has-vertical-middle has-text-centered is-content-width">
          <a class="button is-primary is-small is-inverted"
            :disabled="isLockPurchaseOrder"
            @click="addNewItem(item, true)">
            <span class="icon is-medium">
              <i class="mdi mdi-plus mdi-24px" />
            </span>
          </a>
        </td>
      </tr>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <span icon="icon is-large">
              <i class="mdi mdi-48px mdi-emoticon-sad" />
            </span>
            <p>Nothing</p>
          </div>
        </section>
      </template>
    </bulma-table>
    <confirm-modal v-if="itemToAdd"
      :active.sync="isConfirmModalActive"
      @ok="addNewItem(itemToAdd, false)"
      @cancel="isConfirmModalActive = false"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Item has been ordered</p>
      <p slot="text-content">
        Item <span class="has-text-primary has-text-weight-bold">{{ itemToAdd.itemNo }} - {{ itemToAdd.itemDesc }}</span> has been ordered. Are you sure you want to re-order?
      </p>
    </confirm-modal>
  </div>
</template>

<script>
import _orderBy from 'lodash/orderBy'
import BulmaTable from '@/components/BulmaTable'
import { ItemCategoryTypes } from '@/enums'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { QuotedItemsColumns } from './../columns'
import { PurchaseOrderItemModel } from '@/classes/viewmodels'
import { ConfirmModal } from '@/components/BulmaModal'
import PurchaseOrderService from './../PurchaseOrderService'
import OrderLockNotification from './PurchaseOrderLockNotification'

const title = 'Misc'

export default {
  name: 'QuotedMiscs',
  directives: {},
  components: {
    BulmaTable,
    ConfirmModal,
    OrderLockNotification
  },
  mixins: [NumberFiltersMixin],
  props: {
    value: null,
    readOnlyView: {
      type: Boolean
    },
    quoteId: {
      type: String,
      default: ''
    },
    purchaseOrderId: {
      type: String,
      default: ''
    },
    pageSize: {
      type: Number,
      default: 8
    },
    isLockPurchaseOrder: {
      type: Boolean
    }
  },
  data() {
    return {
      innerValue: null,
      selectedRow: null,
      isTableLoading: false,
      isConfirmModalActive: false,
      filter: {
        quoteId: this.quoteId,
        query: '',
        sortColumn: 'sortNo',
        sortOrder: 'asc',
        pageIndex: 1,
        pageSize: this.pageSize
      },
      availableItems: null,
      itemToAdd: null
    }
  },
  computed: {
    columns() {
      return QuotedItemsColumns
    },
    totalRows() {
      if (this.activeItems) {
        return this.activeItems.length
      } else {
        return 0
      }
    },
    selectedItems() {
      return this.innerValue.filter(i => !i.deleted)
    },
    activeItems() {
      if (this.availableItems) {
        const vm = this
        return _orderBy(
          this.availableItems.filter(function(item) {
            const regex = new RegExp(vm.filter.query, 'i')
            return !item.deleted && !item.reportOnly && (item.itemDesc.match(regex) || item.itemNo.match(regex))
          }),
          this.filter.sortColumn,
          this.filter.sortOrder
        )
      } else {
        return []
      }
    },
    pagedActiveItems() {
      return this.activeItems.slice((this.filter.pageIndex - 1) * this.filter.pageSize, this.filter.pageIndex * this.filter.pageSize)
    }
  },
  watch: {},
  created() {
    this.innerValue = this.value
    this.getAvailableItems()
  },
  mounted() {},
  methods: {
    async getAvailableItems() {
      this.isTableLoading = true
      this.availableItems = await PurchaseOrderService.getQuoteItemsByQuoteId(this.quoteId, this.purchaseOrderId, ItemCategoryTypes.MISC)
      this.isTableLoading = false
    },
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
    },
    search() {
      this.filter.pageIndex = 1
    },
    reset() {
      this.filter.query = ''
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
    },
    addNewItem(item, fromAddItem) {
      const existingItem = this.innerValue.find(
        (i) => i.description === item.itemDesc && i.itemNo === item.itemNo && !i.deleted
      )
      if (existingItem) {
        if (fromAddItem) {
          this.$notification.openNotificationWithType('warning', title, 'Misc item is already in selected list. Item not added.')
        }
        return false
      } else {
        if (fromAddItem) {
          if (item.otherPoOrderedQty >= item.itemQuantity) {
            this.itemToAdd = item
            this.isConfirmModalActive = true
            return false
          }
        }
      }
      let newItem = new PurchaseOrderItemModel(
        this.$userInfo.companyId,
        this.purchaseOrderId,
        item.quoteItemId,
        item.itemNo,
        item.itemDesc,
        'Misc',
        1,
        item.otherPoOrderedQty
      )
      newItem.quantity = 1
      newItem.dealerCost = newItem.unitPrice = item.value
      this.$emit('item-added', newItem)

      if (this.isConfirmModalActive === true) {
        this.itemToAdd = null
        this.isConfirmModalActive = false
      }
    },
    setSelectedAll(selected) {
      if (selected) {
        const items = this.activeItems.filter((i) => i.itemQuantity > i.otherPoOrderedQty)
        for (var i = 0; i < items.length; i++) {
          this.addNewItem(items[i], false)
        }
      } else {
        this.$emit('item-deleted')
      }
    }
  }
}
</script>
