<template>
  <div>
    <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
      :columns="columns"
      :page-index="1"
      :page-size="100"
      :total-rows="activeItems.length"
      :show-pagination="false"
      :is-loading="isTableLoading"
      :sort-column="sortColumn"
      :sort-order="sortOrder"
      @sort="sort">
      <tr v-for="(item, index) in activeItems"
        :key="item.purchaseOrderItemId"
        :class="{ 'is-selected' : selectedRow === index }">
        <td class="itemno-input">{{ item.itemNo }}</td>
        <td class="has-vertical-middle itemdesc-input">{{ item.description }}</td>
        <td class="has-vertical-middle has-text-right money-input">{{ item.dealerCost | formatNumber($userInfo.locale) }}</td>
        <td class="has-vertical-middle has-text-right">{{ item.receivedQty - item.returnQty }}</td>
      </tr>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <span icon="icon is-large">
              <i class="mdi mdi-48px mdi-emoticon-sad" />
            </span>
            <p>Nothing</p>
          </div>
        </section>
      </template>
      <template slot="footer">
        <tr>
          <th class="has-text-right has-text-success"
            :colspan="totalColSpan">Totals</th>
          <th class="has-text-right has-text-success"
            :class="[ miscsTotal >= 0 ? 'has-text-success' : 'has-text-danger' ]">{{ miscsTotal | formatCurrency($userInfo.locale) }}</th>
        </tr>
      </template>
    </bulma-table>
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import _orderBy from 'lodash/orderBy'
import BulmaTable from '@/components/BulmaTable'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { ItemsToOrderColumns } from '../columns'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'

export default {
  name: 'OverviewMisc',
  components: {
    BulmaTable
  },
  filters: {},
  mixins: [NumberFiltersMixin],
  props: {
    value: null
  },
  data() {
    return {
      innerValue: _cloneDeep(this.value),
      isTableLoading: false,
      selectedRow: false,
      sortColumn: '',
      sortOrder: ''
    }
  },
  computed: {
    columns() {
      // return ItemsToOrderColumns
      const c = _cloneDeep(ItemsToOrderColumns)
      c[0].isVisible = this.$userInfo.isSupportUser
      c[c.length - 2].isVisible = this.$userInfo.isSupportUser
      c.splice(c.length - 1, 1)
      return c
    },
    activeItems() {
      return this.value.miscs.filter(i => !i.deleted)
    },
    miscsTotal() {
      return roundAwayFromZero(
        this.activeItems.reduce(function(total, item) {
          return total + item.dealerCost
        }, 0),
        2
      )
    },
    totalColSpan() {
      return this.columns.length - 2
    }
  },
  watch: {
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  created() {},
  mounted() {},
  methods: {
    sort(name, order) {
      this.value.miscs = _orderBy(this.activeItems, name, order)
    }
  }
}
</script>

<style lang="scss" scoped>
.itemno-input {
  min-width: 2.5em;
}
.itemdesc-input {
  min-width: 20em;
}
.money-input {
  max-width: 50px;
}
</style>