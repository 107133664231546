<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile is-vertical is-half">
      <div class="tile is-parent">
        <article class="tile is-child box detail-page-tile">
          <p class="title">Vehicle</p>
          <p v-if="vehicle && !vehicle.isActive"
            class="subtitle has-text-danger">This vehicle has been deleted</p>
          <div class="field">
            <table class="table is-bordered is-striped is-narrow is-fullwidth"
              :class="{ 'is-loading-no-header' : isVehicleLoading }">
              <tbody v-if="!isVehicleLoading && vehicle">
                <tr>
                  <td class="has-text-weight-bold">Rego</td>
                  <td>{{ vehicle.rego }}</td>
                </tr>
                <tr>
                  <td class="has-text-weight-bold">Make</td>
                  <td>{{ vehicle.model.make }}</td>
                </tr>
                <tr>
                  <td class="has-text-weight-bold">Model</td>
                  <td>{{ vehicle.model.model }}</td>
                </tr>
                <tr>
                  <td class="has-text-weight-bold">Series</td>
                  <td>{{ vehicle.model.make }}</td>
                </tr>
                <tr>
                  <td class="has-text-weight-bold">Built</td>
                  <td>{{ vehicle.buildMonth | pad(2) }}{{ vehicle.buildMonth ? '/' : '' }}{{ vehicle.buildYear }}</td>
                </tr>
                <tr>
                  <td class="has-text-weight-bold">Engine Size</td>
                  <td>{{ vehicle.engizeSize }}</td>
                </tr>
                <tr>
                  <td class="has-text-weight-bold">VIN</td>
                  <td>{{ vehicle.vin }}</td>
                </tr>
                <tr>
                  <td class="has-text-weight-bold">Body Style</td>
                  <td>{{ vehicle.bodyId2 }}</td>
                </tr>
                <tr>
                  <td class="has-text-weight-bold">Colour</td>
                  <td>{{ vehicle.colour }}</td>
                </tr>
                <tr>
                  <td class="has-text-weight-bold">Engine No.</td>
                  <td>{{ vehicle.engineNo }}</td>
                </tr>
                <tr>
                  <td class="has-text-weight-bold">Cylinders</td>
                  <td>{{ vehicle.cylinders }}</td>
                </tr>
                <tr>
                  <td class="has-text-weight-bold">Transmission</td>
                  <td>{{ vehicle.transmissionType }}</td>
                </tr>
                <tr>
                  <td class="has-text-weight-bold">Paint Group</td>
                  <td>{{ vehicle.paintGroup }}</td>
                </tr>
                <tr>
                  <td class="has-text-weight-bold">NRMA Schedule</td>
                  <td>{{ vehicle.scheduleId }}</td>
                </tr>
                <tr>
                  <td class="has-text-weight-bold">NTAR Schedule</td>
                  <td>{{ vehicle.ntarScheduleId }}</td>
                </tr>
                <tr>
                  <td class="has-text-weight-bold">MTA Schedule</td>
                  <td>{{ vehicle.mtaScheduleId }}</td>
                </tr>
                <tr>
                  <td class="has-text-weight-bold">Odometer</td>
                  <td>{{ vehicle.odometer }}</td>
                </tr>
              </tbody>
              <tbody v-else-if="isVehicleLoading">
                <tr>
                  <td colspan="2">
                    <section class="section">
                      <div class="content has-text-grey has-text-centered">
                        <span icon="icon is-large"
                          style="opacity: 0;">
                          <i class="mdi mdi-spin mdi-loadingmdi-36px" />
                        </span>
                      </div>
                    </section>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </article>
      </div>
    </div>
    <div class="tile is-vertical is-half">
      <div class="tile is-parent">
        <article class="tile is-child box detail-page-tile">
          <p class="title">Customer</p>
          <p v-if="customer && !customer.active"
            class="subtitle has-text-danger">This customer has been deleted</p>
          <table class="table is-bordered is-striped is-narrow is-fullwidth"
            :class="{ 'is-loading-no-header' : isCustomerLoading }">
            <tbody v-if="!isCustomerLoading && customer">
              <tr>
                <td class="has-text-weight-bold">
                  <span>Name</span>
                  <span v-show="this.$v.vehicleCustomerInsurerGroup.$error"
                    class="icon has-text-danger tooltip is-tooltip-danger is-tooltip-multiline"
                    data-tooltip="Quote's customer does not match vehicle's customer. Click button next to name to fix.">
                    <i class="mdi mdi-alert-circle mdi-18px" />
                  </span>
                </td>
                <td>
                  {{ customer.fullname }}
                  <span v-if="customer && !customer.active"
                    class="tag is-danger is-light">Deleted</span>
                  <button v-show="this.$v.vehicleCustomerInsurerGroup.$error"
                    class="button is-small is-outlined is-danger is-pulled-right tooltip is-tooltip-topright"
                    data-tooltip="Auto fix customer mismatch issue"
                    @click="fixCustomerMismatch()">
                    <span class="icon"><i class="mdi mdi-auto-fix mdi-18px" /></span>
                  </button>
                </td>
              </tr>
              <tr>
                <td class="has-text-weight-bold">Address</td>
                <td>{{ customerDefaultAddress ? customerDefaultAddress.displayAddress : '' }}</td>
              </tr>
              <tr>
                <td class="has-text-weight-bold">Phone</td>
                <td>{{ customerDefaultPhone ? customerDefaultPhone.no : '' }}</td>
              </tr>
              <tr>
                <td class="has-text-weight-bold">Mobile</td>
                <td>{{ customerMobilePhone ? customerMobilePhone.no : '' }}</td>
              </tr>
              <tr>
                <td class="has-text-weight-bold">Fax</td>
                <td>{{ customerFax ? customerFax.no : '' }}</td>
              </tr>
              <tr>
                <td class="has-text-weight-bold">Email</td>
                <td>{{ customerDefaultEmail ? customerDefaultEmail.address : '' }}</td>
              </tr>
            </tbody>
            <tbody v-else-if="isCustomerLoading">
              <tr>
                <td colspan="2">
                  <section class="section">
                    <div class="content has-text-grey has-text-centered">
                      <span icon="icon is-large"
                        style="opacity: 0;">
                        <i class="mdi mdi-spin mdi-loading mdi-36px" />
                      </span>
                    </div>
                  </section>
                </td>
              </tr>
            </tbody>
          </table>
        </article>
      </div>
      <div class="tile is-parent">
        <article class="tile is-child box detail-page-tile">
          <p class="title">Insurer</p>
          <p v-if="insurer && !insurer.active"
            class="subtitle has-text-danger">This insurer has been deleted</p>
          <table class="table is-bordered is-striped is-narrow is-fullwidth"
            :class="{ 'is-loading-no-header' : isInsurerLoading }">
            <tbody v-if="!isInsurerLoading && insurer">
              <tr>
                <td class="has-text-weight-bold">
                  <span>Name</span>
                </td>
                <td>
                  {{ insurer.name }}
                  <span v-if="insurer && !insurer.active"
                    class="tag is-danger is-light">Deleted</span>
                </td>
              </tr>
              <tr>
                <td class="has-text-weight-bold">Address</td>
                <td>{{ insurerDefaultAddress ? insurerDefaultAddress.displayAddress : '' }}</td>
              </tr>
              <tr>
                <td class="has-text-weight-bold">Phone</td>
                <td>{{ insurerDefaultPhone ? insurerDefaultPhone.no : '' }}</td>
              </tr>
              <tr>
                <td class="has-text-weight-bold">Mobile</td>
                <td>{{ insurerMobilePhone ? insurerMobilePhone.no : '' }}</td>
              </tr>
              <tr>
                <td class="has-text-weight-bold">Fax</td>
                <td>{{ insurerFax ? insurerFax.no : '' }}</td>
              </tr>
              <tr>
                <td class="has-text-weight-bold">Email</td>
                <td>{{ insurerDefaultEmail ? insurerDefaultEmail.address : '' }}</td>
              </tr>
            </tbody>
            <tbody v-else-if="isInsurerLoading">
              <tr>
                <td colspan="2">
                  <section class="section">
                    <div class="content has-text-grey has-text-centered">
                      <span icon="icon is-large"
                        style="opacity: 0;">
                        <i class="mdi mdi-spin mdi-loading mdi-36px" />
                      </span>
                    </div>
                  </section>
                </td>
              </tr>
            </tbody>
          </table>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import QuoteService from '@/views/quote/QuoteService'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { PhoneTypes } from '@/enums'
import { mapActions } from 'vuex'

export default {
  name: 'PurchaseOrderVehicleCustomerInsurer',
  inject: ['$vv'],
  components: {},
  mixins: [NumberFiltersMixin],
  props: {
    value: null,
    vehicle: null,
    customer: null,
    insurer: null,
    readOnlyView: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerValue: null,
      isVehicleLoading: false,
      isCustomerLoading: false,
      isInsurerLoading: false
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    customerDefaultAddress() {
      return this.customer.addresses.find(a => a.isDefault)
    },
    customerDefaultPhone() {
      return this.customer.phones.find(a => a.isDefault)
    },
    customerMobilePhone() {
      return this.customer.phones.find(a => a.type === PhoneTypes.Mobile.toString())
    },
    customerFax() {
      return this.customer.phones.find(a => a.type === PhoneTypes.Fax.toString())
    },
    customerDefaultEmail() {
      return this.customer.emails.find(e => e.isDefault)
    },
    insurerDefaultAddress() {
      return this.insurer.addresses.find(a => a.isDefault)
    },
    insurerDefaultPhone() {
      return this.insurer.phones.find(a => a.isDefault)
    },
    insurerMobilePhone() {
      return this.insurer.phones.find(a => a.type === PhoneTypes.Mobile.toString())
    },
    insurerFax() {
      return this.insurer.phones.find(a => a.type === PhoneTypes.Fax.toString())
    },
    insurerDefaultEmail() {
      return this.insurer.emails.find(e => e.isDefault)
    }
  },
  watch: {
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  created() {
    this.innerValue = this.value
  },
  mounted() {
    this.$vv.vehicleCustomerInsurerGroup.$touch()
  },
  methods: {
    ...mapActions('quotes', ['getQuoteCustomer']),
    async getVehicleEntity() {
      this.isVehicleLoading = true
      this.vehicle = await QuoteService.getVehicleEntity(this.innerValue.vehicleId)
      this.isVehicleLoading = false
    },
    async getCustomerEntity() {
      this.isCustomerLoading = true
      this.customer = await QuoteService.getCustomerEntity(this.innerValue.customerId)
      this.isCustomerLoading = false
    },
    async getEntities() {
      this.isVehicleLoading = true
      this.isCustomerLoading = true
      ;[this.vehicle, this.customer] = await Promise.all([
        QuoteService.getVehicleEntity(this.innerValue.vehicleId),
        QuoteService.getCustomerEntity(this.innerValue.customerId)
      ])
      this.isVehicleLoading = false
      this.isCustomerLoading = false
    },
    fixCustomerMismatch() {
      this.isCustomerLoading = true
      this.innerValue.customerId = this.vehicle.customer.key
      this.getQuoteCustomer({ id: this.innerValue.customerId, refresh: true })
      this.isCustomerLoading = false
    }
  }
}
</script>
