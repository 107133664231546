<template>
  <div>
    <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
      :columns="columns"
      :page-index="1"
      :page-size="100"
      :total-rows="activeItems.length"
      :show-pagination="false"
      :is-loading="isTableLoading"
      :sort-column="sortColumn"
      :sort-order="sortOrder"
      @sort="sort">
      <tr v-for="(item, index) in activeItems"
        :key="item.purchaseOrderItemId"
        @click="highlightSelected(index, $event)">
        <td class="itemtype-width">{{ item.itemType | itemType }}</td>
        <td>{{ item.description }}</td>
        <td class="has-text-right">{{ item.quantity }}</td>
        <td class="has-text-right">{{ item.receivedQty }}</td>
        <td class="has-text-right">{{ item.returnQty }}</td>
        <td class="has-text-right total-width">{{ item.dealerCost | formatNumber($userInfo.locale) }}</td>
      </tr>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <span icon="icon is-large">
              <i class="mdi mdi-48px mdi-emoticon-sad" />
            </span>
            <p>Nothing</p>
          </div>
        </section>
      </template>
      <template slot="footer">
        <tr>
          <th class="has-text-right has-text-success"
            :colspan="5">Totals</th>
          <th class="has-text-right has-text-success"
            :class="[ (otherPurchasesTotal + subletTotal + miscTotal + partTotal) >= 0 ? 'has-text-success' : 'has-text-danger' ]">{{ (otherPurchasesTotal + subletTotal + miscTotal + partTotal) | formatCurrency($userInfo.locale) }}</th>
        </tr>
      </template>
    </bulma-table>
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import _orderBy from 'lodash/orderBy'
import BulmaTable from '@/components/BulmaTable'
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'
import { DetailSummaryColumns } from './columns'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'

export default {
  name: 'PurchaseOrderDetailSummary',
  components: {
    BulmaTable
  },
  filters: {
    itemType(itemType) {
      switch (itemType) {
        case 'PART':
          return 'Parts'
          break
        case 'MISC':
          return 'Misc'
          break
        case 'SUBL':
          return 'Sublet'
          break
        case 'OTHER':
          return 'Other Purchases'
          break
      }
    }
  },
  mixins: [DateTimeFiltersMixin, NumberFiltersMixin],
  props: {
    value: null,
    itemSummaries: null
  },
  data() {
    return {
      innerValue: _cloneDeep(this.value),
      activeItems: _cloneDeep(this.itemSummaries),
      isTableLoading: false,
      sortColumn: '',
      sortOrder: ''
    }
  },
  computed: {
    columns() {
      return DetailSummaryColumns
    },
    // displayRemarks() {
    //   return this.value.remarks
    //     .filter(
    //       r => r.remarkTypeId === RemarkTypes.Remark
    //     )
    //     .slice(0, 5)
    // },
    otherPurchasesItems() {
      return this.innerValue.otherPurchases.filter(i => !i.deleted)
    },
    partItems() {
      return this.innerValue.parts.filter(i => !i.deleted)
    },
    miscItems() {
      return this.innerValue.miscs.filter(i => !i.deleted)
    },
    subletItems() {
      return this.innerValue.sublets.filter(i => !i.deleted)
    },
    otherPurchasesTotal() {
      return roundAwayFromZero(
        this.otherPurchasesItems.reduce(function(total, item) {
          return total + item.dealerCost
        }, 0),
        2
      )
    },
    partTotal() {
      return roundAwayFromZero(
        this.partItems.reduce(function(total, item) {
          return total + item.dealerCost
        }, 0),
        2
      )
    },
    miscTotal() {
      return roundAwayFromZero(
        this.miscItems.reduce(function(total, item) {
          return total + item.dealerCost
        }, 0),
        2
      )
    },
    subletTotal() {
      return roundAwayFromZero(
        this.subletItems.reduce(function(total, item) {
          return total + item.dealerCost
        }, 0),
        2
      )
    },
    total() {
      return miscTotal
    }
  },
  watch: {},
  created() {},
  mounted() {
  },
  methods: {
    sort(name, order) {
      this.activeItems = _orderBy(this.activeItems, name, order)
    }
  }
}
</script>
<style lang="scss" scoped>
.is-scrollable {
  overflow-y: auto;
  height: 400px;
}
.itemtype-width {
  width: 140px;
}
.total-width {
  width: 100px;
}
</style>
