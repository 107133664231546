<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile is-parent is-vertical">
      <article class="tile is-child box detail-page-tile">
        <div class="columns is-gapless">
          <div class="column">
            <p class="title">Items Overview</p>
            <div class="tabs is-boxed">
              <ul>
                <li v-show="otherPurchasesItems.length > 0"
                  :class="{ 'is-active' : activeTab === 'OtherPurchases' }"><a @click="tabClicked('OtherPurchases')">Other Purchases</a></li>
                <li v-show="partItems.length > 0"
                  :class="{ 'is-active' : activeTab === itemCategoryTypes.PART }"><a @click="tabClicked(itemCategoryTypes.PART)">Part</a></li>
                <li v-show="miscItems.length > 0"
                  :class="{ 'is-active' : activeTab === itemCategoryTypes.MISC }"><a @click="tabClicked(itemCategoryTypes.MISC)">Misc</a></li>
                <li v-show="subletItems.length > 0"
                  :class="{ 'is-active' : activeTab === itemCategoryTypes.SUBL }"><a @click="tabClicked(itemCategoryTypes.SUBL)">Sublet</a></li>
              </ul>
            </div>
          </div>
        </div>
        <overview-other-purchases v-if="isOtherPurchases"
          :value="value"
          :item-type="activeTab" />
        <overview-part v-if="isPart"
          :value="value"
          :item-type="activeTab" />
        <overview-misc v-if="isMisc"
          :value="value"
          :item-type="activeTab" />
        <overview-sublet v-if="isSublet"
          :value="value"
          :item-type="activeTab" />
      </article>
    </div>
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import { EventHubTypes, ItemCategoryTypes } from '@/enums'
import { OtherPurchasesColumns } from './columns'
import OverviewOtherPurchases from './components/OverviewOtherPurchases'
import OverviewPart from './components/OverviewPart'
import OverviewMisc from './components/OverviewMisc'
import OverviewSublet from './components/OverviewSublet'

export default {
  name: 'PurchaseOrderOverview',
  components: {
    OverviewOtherPurchases,
    OverviewPart,
    OverviewMisc,
    OverviewSublet
  },
  filters: {},
  mixins: [],
  props: {
    value: null
  },
  data() {
    return {
      innerValue: _cloneDeep(this.value),
      isTableLoading: false,
      selectedRow: false,
      activeTab: ''
    }
  },
  computed: {
    columns() {
      return OtherPurchasesColumns
    },
    itemCategoryTypes() {
      return ItemCategoryTypes
    },
    isOtherPurchases() {
      return this.activeTab === 'OtherPurchases'
    },
    isPart() {
      return this.activeTab === ItemCategoryTypes.PART
    },
    isMisc() {
      return this.activeTab === ItemCategoryTypes.MISC
    },
    isSublet() {
      return this.activeTab === ItemCategoryTypes.SUBL
    },
    otherPurchasesItems() {
      return this.innerValue.otherPurchases.filter(i => !i.deleted)
    },
    partItems() {
      return this.innerValue.parts.filter(i => !i.deleted)
    },
    miscItems() {
      return this.innerValue.miscs.filter(i => !i.deleted)
    },
    subletItems() {
      return this.innerValue.sublets.filter(i => !i.deleted)
    }
  },
  watch: {},
  beforeDestroy() {
    this.$eventHub.$off(EventHubTypes.EntitySaved)
    this.$eventHub.$off(EventHubTypes.EntityReload)
  },
  created() {
    this.selectInitialTab()
  },
  mounted() {
    this.$eventHub.$on(EventHubTypes.EntitySaved, () => {
      this.innerValue = _cloneDeep(this.value)
    })
    this.$eventHub.$on(EventHubTypes.EntityReload, () => {
      this.innerValue = _cloneDeep(this.value)
    })
  },
  methods: {
    tabClicked(type) {
      this.activeTab = type
    },
    selectInitialTab() {
      if (this.otherPurchasesItems.length > 0) {
        this.activeTab = 'OtherPurchases'
      } else if (this.partItems.length > 0) {
        this.activeTab = ItemCategoryTypes.PART
      } else if (this.miscItems.length > 0) {
        this.activeTab = ItemCategoryTypes.MISC
      } else if (this.subletItems.length > 0) {
        this.activeTab = ItemCategoryTypes.SUBL
      }
    }
  }
}
</script>