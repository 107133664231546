import { minLength, required } from 'vuelidate/lib/validators'
import { guidValidator } from '@/components/validators'
import { abnValidator, abnMinLength } from '@/components/validators'
import Guid from '@/components/Guid'
import store from '@/store'

const countryCode = () => {
  const company = store.getters['company/company']
  return company.info.countryCode
}

const shouldValidateAbn = () => {
  if (countryCode() === 'AU') {
    return true
  } else {
    return false
  }
}

export default {
  provide: function() {
    return {
      $vv: this.$v
    }
  },
  validations: {
    detailGroup: ['entity.vendorId', 'entity.quoteId', 'entity.orderer'],
    vehicleCustomerInsurerGroup: ['entity.customerId'],
    receiptGroup: ['entity.orderReceipts'],
    // otherPurchasesGroup: ['entity.otherPurchases'],
    entity: {
      quoteId: {
        // required
        isValidQuoteNo: (value, entity) => {
          if (value !== Guid.empty() || (value === Guid.empty() && entity.quoteNo === '')) {
            return true
          } else {
            return false
          }
        }
      },
      quoteNo: {},
      vendorId: {
        required,
        validGuid: guidValidator
      },
      orderer: {
        required
      },
      customerId: {
        required,
        validGuid: guidValidator
      },
      otherPurchases: {
        $each: {
          description: {
            required
          }
        }
      },
      orderReceipts: {
        $each: {
          isValid: {
            required,
            isValidInvoice: (value) => value === true
          },
          abn: {
            minLength: minLength(abnMinLength(countryCode())),
            validAbn: (value) => {
              if (shouldValidateAbn()) {
                return abnValidator(value)
              } else {
                return true
              }
            }
          }
        }
      }
    }
  }
}
