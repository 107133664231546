<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile is-parent is-vertical">
      <article class="tile is-child box detail-page-tile">
        <div class="columns is-gapless">
          <div class="column">
            <p class="title">Order Receipt</p>
          </div>
          <div v-if="!readOnlyView && poItems.length > 0"
            class="column">
            <div v-if="$user.hasAdd($route.meta.id)"
              class="control is-pulled-right">
              <div v-if="!isLockPurchaseOrder"
                class="field-body">
                <div class="field"
                  style="margin-right: 0.5em">
                  <div class="buttons">
                    <button class="button is-primary tooltip is-tooltip-topright"
                      data-tooltip="Add new labour"
                      @click="addNewOrderReceipt()">
                      <span class="icon">
                        <i class="mdi"
                          :class="{ 'mdi-plus' : true}" />
                      </span>
                      <span>{{ 'Add' }}</span>
                    </button>
                  </div>
                </div>
              </div>
              <div v-else>
                <order-lock-notification />
              </div>
            </div>
          </div>
        </div>
        <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
          :columns="columns"
          :page-index="1"
          :page-size="100"
          :total-rows="entitySummaries.length"
          :show-pagination="false"
          :is-loading="isTableLoading"
          :sort-column="sortColumn"
          :sort-order="sortOrder"
          @sort="sort">
          <tr v-for="(receipt, index) in entitySummaries"
            :key="receipt.creditorInvoiceId"
            :class="{ 'is-selected' : selectedRow === index }"
            @click="highlightSelected(index, $event)">
            <td class="invoiceno-width">
              <a @click="viewReceipt(receipt.creditorInvoiceId)">{{ receipt.invoiceNo }}</a>
              <span v-show="(!receipt.isValid || (receipt.abn != '' && $v.entity.orderReceipts.$each[index].$error)) && receipt.receiptItems.length > 0"
                class="icon has-text-danger tooltip is-tooltip-danger"
                data-tooltip="Please fix the order receipt error.">
                <i class="mdi mdi-alert-circle mdi-18px" />
              </span>
            </td>
            <td class="invoicedate-width">{{ $filters.formatDateTimezone(receipt.invoiceDate, $userInfo.locale) }}</td>
            <td class="abn-width">{{ receipt.abn }}</td>
            <td class="has-text-centered is-content-width">
              <button class="button is-primary is-small is-inverted tooltip"
                @click="viewReceipt(receipt.creditorInvoiceId)"
                data-tooltip="Click to view">
                <span class="icon is-medium">
                  <i class="mdi mdi-file-document mdi-24px" />
                </span>
              </button>
              <button v-show="$user.hasDelete($route.meta.id)"
                class="button is-danger is-small is-inverted tooltip is-tooltip-topright"
                @click="deleteReceipt(receipt, index)"
                :disabled="readOnlyView || receipt.exported"
                data-tooltip="Click to delete">
                <span class="icon is-medium">
                  <i class="mdi mdi-delete mdi-24px" />
                </span>
              </button>
            </td>
          </tr>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <span icon="icon is-large">
                  <i class="mdi mdi-48px mdi-emoticon-sad" />
                </span>
                <p>Nothing</p>
              </div>
            </section>
          </template>
        </bulma-table>
      </article>
    </div>
    <confirm-modal v-if="currentReceipt"
      :active.sync="isConfirmModalActive"
      @ok="confirmDelete(true)"
      @cancel="confirmDelete(false)"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Delete Order Receipt</p>
      <p slot="text-content">
        <span class="has-text-primary has-text-weight-bold">{{ currentReceipt.invoiceNo }}</span> will be deleted. Continue?
      </p>
    </confirm-modal>
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import _orderBy from 'lodash/orderBy'
import BulmaTable from '@/components/BulmaTable'
import Guid from '@/components/Guid'
import { ConfirmModal } from '@/components/BulmaModal'
import { EventHubTypes } from '@/enums'
import { NumberFiltersMixin, DateTimeFiltersMixin } from '@/components/mixins/filters'
import StoreMixin from './storeMixin'
import { OrderReceiptListColumns } from './columns'
import { PurchaseOrderReceiptModel, PurchaseOrderReceiptItemModel } from '@/classes/viewmodels'
import OrderLockNotification from './components/PurchaseOrderLockNotification'

const title = 'Order Receipt'

export default {
  name: 'PurchaseOrderOrderReceipt',
  inject: ['$vv'],
  components: {
    BulmaTable,
    ConfirmModal,
    OrderLockNotification
  },
  filters: {},
  mixins: [StoreMixin, NumberFiltersMixin, DateTimeFiltersMixin],
  props: {
    value: null
  },
  data() {
    return {
      // totalIncGst: 0,
      // totalGst: 0,
      innerValue: _cloneDeep(this.value),
      isTableLoading: false,
      isConfirmModalActive: false,
      currentReceipt: null,
      selectedRow: false,
      selectedInvoiceDate: null,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      isLoadAttachmentsActive: false,
      sortColumn: '',
      sortOrder: ''
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    columns() {
      return OrderReceiptListColumns
    },
    entitySummaries() {
      return this.innerValue.orderReceipts.filter((i) => !i.isDeleted)
    },
    poItems() {
      return this.innerValue.parts.concat(this.innerValue.miscs, this.innerValue.sublets, this.innerValue.otherPurchases)
    }
  },
  watch: {
    innerValue: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  beforeDestroy() {
    this.$eventHub.$off(EventHubTypes.EntitySaved)
    this.$eventHub.$off(EventHubTypes.EntityReload)
  },
  created() {},
  mounted() {
    this.$eventHub.$on(EventHubTypes.EntitySaved, () => {
      this.innerValue = _cloneDeep(this.value)
    })
    this.$eventHub.$on(EventHubTypes.EntityReload, () => {
      this.innerValue = _cloneDeep(this.value)
    })
  },
  methods: {
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    viewReceipt(creditorInvoiceId) {
      // if (this.snapshotDiff) {
      //   this.$notification.openNotificationWithType('danger', title, 'There are changes pending to save. Please save before proceed.')
      //   return false
      // }
      this.$router.push({
        name: 'PurchaseOrderOrderReceiptDetail',
        params: { creditorInvoiceId: creditorInvoiceId }
      })
    },
    deleteReceipt(receipt) {
      if (receipt.isReturn) {
        this.$notification.openNotificationWithType('danger', title, 'Parts have been returned. You cannot delete this receipt.')
        return false
      }

      if (this.snapshotDiff && !receipt.isNew) {
        this.$notification.openNotificationWithType('danger', title, 'There are changes pending to save. Please save before proceed.')
        return false
      }

      if (receipt.gstPaid !== null) {
        this.$notification.openNotificationWithType('danger', title, 'This order receipt cannot be deleted. GST amount has been paid.')
        return false
      }
      this.currentReceipt = receipt
      this.isConfirmModalActive = true
    },
    async confirmDelete(decision) {
      let receipt = this.currentReceipt
      this.isConfirmModalActive = false
      if (decision) {
        for (let i = 0; i < this.currentReceipt.receiptItems.length; i++) {
          var poItemId = this.currentReceipt.receiptItems[i].purchaseOrderItemId
          // var poItem = this.poItems.filter(i => i.purchaseOrderItemId === poItemId && !i.deleted)[0]
          var poItem = this.poItems.filter((i) => i.purchaseOrderItemId === poItemId)[0]
          poItem.receivedQty = poItem.receivedQty - this.currentReceipt.receiptItems[i].received
          poItem.totalInStock = poItem.totalInStock - this.currentReceipt.receiptItems[i].received

          //calculate totalReceivedQty for all receipt order for the same PO item
          this.innerValue.orderReceipts.forEach(function (receipt) {
            let found = receipt.receiptItems.some((i) => i.purchaseOrderItemId === poItemId)
            if (found) {
              receipt.receiptItems.filter((i) => i.purchaseOrderItemId === poItemId)[0].totalReceivedQty = poItem.receivedQty
            }
          })
        }

        if (receipt.isNew) {
          const itemIndex = this.innerValue.orderReceipts
            .map(function (obj) {
              return obj.creditorInvoiceId
            })
            .indexOf(receipt.creditorInvoiceId)
          if (itemIndex >= 0) {
            this.innerValue.orderReceipts.splice(itemIndex, 1)
            // this.items.splice(index, 1)
          }
        } else {
          receipt.deleted = true
          receipt.isDeleted = true
        }
      }
      this.currentReceipt = null
    },
    addNewOrderReceipt() {
      if (this.snapshotDiff) {
        this.$notification.openNotificationWithType('danger', title, 'There are changes pending to save. Please save before proceed.')
        return false
      }

      let creditorInvoiceId = Guid.newGuid()
      const newReceipt = new PurchaseOrderReceiptModel(
        this.innerValue.companyId,
        this.innerValue.purchaseOrderId,
        creditorInvoiceId,
        this.innerValue.vendorId,
        this.innerValue.vendor.abn,
        this.innerValue.vendor.name,
        this.innerValue.quoteId,
        this.innerValue.vendorGlCode,
        this.innerValue.gstRate
      )
      newReceipt.invoiceDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()) //new Date().toISOString()
      this.innerValue.orderReceipts.push(newReceipt)

      let lastIndex = this.innerValue.orderReceipts.length - 1
      let receiptItems = this.innerValue.parts.concat(this.innerValue.miscs, this.innerValue.sublets, this.innerValue.otherPurchases)

      if (receiptItems.length > 0) {
        for (let i = 0; i < receiptItems.length; i++) {
          if (receiptItems[i].quantity > receiptItems[i].receivedQty - receiptItems[i].returnQty) {
            const newItem = new PurchaseOrderReceiptItemModel(
              this.innerValue.companyId,
              creditorInvoiceId,
              this.innerValue.vendorId,
              receiptItems[i].purchaseOrderItemId
            )
            const date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
            newItem.quote2ItemId = receiptItems[i].quoteItemId !== null ? receiptItems[i].quoteItemId : Guid.empty()
            newItem.receivedDate = date.toISOString().split('.')[0] + 'Z'
            newItem.account = this.innerValue.vendorGlCode
            newItem.description = receiptItems[i].description
            newItem.itemNo = receiptItems[i].itemNo
            newItem.side = receiptItems[i].side
            newItem.orderQty = receiptItems[i].quantity
            newItem.owning = receiptItems[i].quantity - receiptItems[i].receivedQty + receiptItems[i].returnQty
            newItem.totalReceivedQty = receiptItems[i].receivedQty
            newItem.partNo = receiptItems[i].partNo
            newItem.unitCost = receiptItems[i].qtePrice
            newItem.dealerCost =
              (receiptItems[i].quantity - (receiptItems[i].receivedQty - receiptItems[i].returnQty)) * (receiptItems[i].dealerCost / receiptItems[i].quantity)
            newItem.gstAmount = newItem.dealerCost * (this.innerValue.gstRate / 100)
            newItem.totalAmount = newItem.dealerCost + newItem.gstAmount
            this.innerValue.orderReceipts[lastIndex].receiptItems.push(newItem)
          }
        }
      }
      this.$router.push({
        name: 'PurchaseOrderOrderReceiptDetail',
        params: { creditorInvoiceId: creditorInvoiceId }
      })
    },
    sort(name, order) {
      this.innerValue.orderReceipts = _orderBy(this.entitySummaries, name, order)
    }
  }
}
</script>

<style lang="scss" scoped>
.invoiceno-width {
  min-width: 12em;
}
.invoicedate-width {
  min-width: 8em;
}
.abn-width {
  min-width: 8em;
}
</style>